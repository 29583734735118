.faq-page {
  .faq-group {
    margin-bottom: 34px;
    h2 {
      margin-bottom: 14px;
    }
  }
  ul {
    list-style: initial;
    padding-left: 40px;
  }
}