@import "base";
@import "responsive";
@import 'font-awesome';
@import "awesome-bootstrap-checkbox";
//@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&subset=latin-ext');
@import url('../fonts/lato.css');

/* ------------------------------------------------------------------- */
/* Table of Contents
----------------------------------------------------------------------

1. Header
2. Navigation
3. Miscellaneous
4. Schortcodes
5. Revolution Slider
6. Royal Slider
7. ShowBiz Carousel
8. Widgets
9. Portfolio
10. Blog Styles
11. Footer
12. Media Queries

----------------------------------------------------------------------*/

/* ------------------------------------------------------------------- */
/* Header
---------------------------------------------------------------------- */

#top-bar {
    .mobile {
        display: none;
    }
    @media (max-width: 990px) {
        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }
        .topbar-left {
            ul {
                li {
                    font-size:13px;
                    &.separator-line {
                        display: block;
                    }
                }
            }
        }
        .topbar-right {
            margin-right: 20px;
            ul.language-selector {
                > li {
                    line-height: 62px !important;
                }
            }
        }
    }
    @media (max-width: 767px) {
        background: $white !important;
        color: $topbar-blue !important;
        ul {
            li {
                a {
                    color: $topbar-blue !important;
                    &:hover, &:focus, &:active {
                        color: $topbar-blue !important;
                    }
                }
            }
        }
        .topbar-left {
            ul {
                li {
                    display: block !important;
                    font-size:12px;
                    text-align: left !important;
                    padding: 0 10px;
                    &.separator-line {
                        display: block;
                    }
                }
            }
        }
    }
    background: $topbar-blue;
    color: $white;
    padding: 0;
    ul {
        li {
            font-size: 14px;
            a {
                color: $white;
                transition: 0.3s ease-in-out;
                &:hover, &:focus, &:active {
                    text-decoration: underline;
                }
            }
            ion-icon {
                padding-right: 10px;
            }
        }
    }
    .topbar-left {
        ul {
            li {
                display: inline;
                padding-right: 10px;
            }
        }
        padding: 13px 0;
    }
    .topbar-right {
        float: right;
        ul {
            li {
                display: inline-block;
                line-height: 50px;
            }
        }

        .language-selector-desktop{
            @include screen-max-360{
                ul.language-selector {
                    > li {
                        > a{
                            .lang-label {
                                display:none;
                            }
                        }
                    }
                }
            }
        }
    }

    .header-info-line {
        float:left;
        span{
            font-weight: 400;
            line-height: 34px;
            &.text-blue{
                color: $light-blue;
            }
        }
    }
    .free-shipping-xl{
        a{
            color: $white !important;
        }
    }
    .free-shipping-lg-down{
        a{
            color: $white !important;
        }
    }
    @media (min-width: 1400px) {
        .free-shipping-lg-down{
            display: none !important;
        }
    }
    @media (max-width: 1399px) {
        .free-shipping-xl{
            display: none !important;
        }
    }
    @media (max-width: 610px) {
        .free-shipping-lg-sm-down{
            display: none !important;
        }
        .support-phone{
            display: none !important;
        }
    }
}

ul.top-bar-menu {
    margin: 5px 0;
    text-align: right;
    float: right;
    > li {
        margin: 0 0 0 20px;
    }
    @include screen-xs{
        float:left;
        text-align: left;
        > li {
            margin: 0 0 0 10px;
        }
    }
    li {
        font-size: 13px;
        display: inline;
        a {
            color: $white;
        }
        i {
            color: $white;
            margin: 0 5px 0 0;
        }
    }
}

/* Logo
------------------------------------- */

.menu-wrapper{
    height: 90px;
    border-bottom: 1px solid $header-separator;
    #logo {
        margin-top: 20px;
        margin-right: 20px;
        float:left;
        a.logo-link {
            display: inline-block;
            img {
                float: left;
                height: 55px;
            }
        }
    }
    .mobile-search-trigger {
        font-size: 30px;
        color:$dark-grey2;
        display: block;
        padding: 7px 10px;
    }
    .menu-container{
        float:left;
        width:610px;
        @include screen-sm-down{
            width: 100%;
        }
    }
    @include screen-xs{
        height: 45px;
        > .container{
            margin: 0;
            width: 100%;
        }
    }
}
.menu-wrapper-2 {
    background-color: $white;
    height: 54px;
}

.hax-login-button {
    float:right !important;
    a{
        background-color: $virusmask-main !important;
        color: $white !important;
        &:hover{
            background-color: $light-blue-hover !important;
        }
    }
}
/* Language / Currency
------------------------------------- */

.top-bar-dropdown {
    cursor: pointer;
    display: inline-block;
    position: relative;
    .options {
        padding: 20px 0;
        text-align: left;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        margin: 15px 0 0 0;
        z-index: 101;
        -webkit-transition: opacity 200ms ease-in-out;
        -moz-transition: opacity 200ms ease-in-out;
        -ms-transition: opacity 200ms ease-in-out;
        -o-transition: opacity 200ms ease-in-out;
        transition: opacity 200ms ease-in-out;
        right:0;
    }
    &.active .options {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
    }
}

.arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $dark-grey;
    position: absolute;
    top: -4px;
    left: 6px;
}

.top-bar-dropdown {
    ul {
        background: $white;
        width: 170px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        li {
            width: 100%;
            display: block;
            a {
                padding: 5px 30px;
                display: block;
                color: $dark-grey2;
                z-index: 10;
                position: relative;
                transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
                &:hover {
                    background-color: #ddf9ff !important;
                    color: $dark-grey2 !important;
                    z-index: 1;
                    position: relative;
                }
            }
        }
    }
}

/* Social Icons
------------------------------------- */

.social-icons li {
    padding-top: 30px;
    display: inline;
    float: left;
    margin-right: 4px;
    a {
        width: 42px;
        height: 42px;
        display: inline-block;
        background-color: #cacaca;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
    }
}

/* Header Icon Styles */

#top-bar .social-icons {
    float: right;
    li {
        margin: 0 0 -4px 0;
        padding: 0;
        float: left;
        a {
            width: 36px;
            height: 34px;
            margin: 0;
            padding: 0;
            &:before, i {
                font-size: 14px;
            }
        }
        .twitter {
            &:before, i:before {
                font-size: 12px;
            }
        }
    }
}

/* Common Styles */

.social-icons li a {
    i {
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        position: absolute;
        z-index: 20;
    }
    &:hover {
        color: $white;
    }
    i {
        color: $white;
        top: -30px;
        opacity: 0;
    }
}

.the-team-social.social-icons li a i, .social-icons li a:hover i {
    top: 0;
    opacity: 1;
}

.twitter:before, .wordpress:before, .facebook:before, .linkedin:before, .steam:before, .tumblr:before, .github:before, .delicious:before, .instagram:before, .xing:before, .amazon:before, .dropbox:before, .paypal:before, .lastfm:before, .gplus:before, .yahoo:before, .pinterest:before, .dribbble:before, .flickr:before, .reddit:before, .vimeo:before, .spotify:before, .rss:before, .youtube:before, .blogger:before, .appstore:before, .digg:before, .evernote:before, .fivehundredpx:before, .forrst:before, .stumbleupon:before, .dribbble:before {
    font-family: "fontello";
    font-size: 16px;
    line-height: 16px;
    color: $white;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    position: absolute;
    z-index: 20;
}

.the-team a:before {
    display: none;
}

#top-bar .social-icons a:before {
    color: lighten($grey, 22%);
}

/* Icon Styles */

.twitter:before {
    font-size: 14px;
}

.icon-twitter {
    font-size: 14px !important;
}

.twitter {
    i, &:before {
        margin: 13px 0 0 12px;
    }
}

#top-bar .twitter {
    i, &:before {
        margin: 10px 0 0 11px;
    }
}

.twitter {
    &:before {
        content: "\54";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $twitter;
    }
}

.the-team .twitter {
    background-color: $twitter;
}

.dribbble {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .dribbble {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.dribbble {
    &:before {
        content: "\44";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $dribble;
    }
}

.the-team .dribbble {
    background-color: $dribble;
}

.facebook {
    i, &:before {
        margin: 13px 0 0 16px;
    }
}

#top-bar .facebook {
    i, &:before {
        margin: 11px 0 0 14px;
    }
}

.facebook {
    &:before {
        content: "\66";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $facebook;
    }
}

.the-team .facebook {
    background-color: $facebook;
}

.linkedin {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .linkedin {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.linkedin {
    &:before {
        content: "\4c";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $linkedin;
    }
}

.the-team .linkedin {
    background-color: $linkedin;
}

.delicious {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .delicious {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.delicious {
    &:before {
        content: "\23";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $delicious;
    }
}

.the-team .delicious {
    background-color: $delicious;
}

.paypal {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .paypal {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.paypal {
    &:before {
        content: "\24";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $paypal;
    }
}

.the-team .paypal {
    background-color: $paypal;
}

.gplus {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .gplus {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.gplus {
    &:before {
        content: "\2b";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $gplus;
    }
}

.the-team .gplus {
    background-color: $gplus;
}

.stumbleupon {
    i, &:before {
        margin: 13px 0 0 11px;
    }
}

#top-bar .stumbleupon {
    i, &:before {
        margin: 11px 0 0 9px;
    }
}

.stumbleupon {
    &:before {
        content: "\2f";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $stumbleupon;
    }
}

.the-team .stumbleupon {
    background-color: $stumbleupon;
}

.fivehundredpx {
    i, &:before {
        margin: 13px 0 0 11px;
    }
}

#top-bar .fivehundredpx {
    i, &:before {
        margin: 11px 0 0 9px;
    }
}

.fivehundredpx {
    &:before {
        content: "\30";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: darken($grey, 27%);
    }
}

.the-team .fivehundredpx {
    background-color: darken($grey, 27%);
}

.pinterest {
    i, &:before {
        margin: 13px 0 0 15px;
    }
}

#top-bar .pinterest {
    i, &:before {
        margin: 11px 0 0 13px;
    }
}

.pinterest {
    &:before {
        content: "\31";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $pinterest;
    }
}

.the-team .pinterest {
    background-color: $pinterest;
}

.forrst {
    i, &:before {
        margin: 13px 0 0 15px;
    }
}

#top-bar .forrst {
    i, &:before {
        margin: 11px 0 0 13px;
    }
}

.forrst {
    &:before {
        content: "\3a";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $forrst;
    }
}

.the-team .forrst {
    background-color: $forrst;
}

.digg {
    i, &:before {
        margin: 13px 0 0 12px;
    }
}

#top-bar .digg {
    i, &:before {
        margin: 11px 0 0 10px;
    }
}

.digg {
    &:before {
        content: "\3b";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $digg;
    }
}

.the-team .digg {
    background-color: $digg;
}

.spotify {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .spotify {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.spotify {
    &:before {
        content: "\3d";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $green;
    }
}

.the-team .spotify {
    background-color: $green;
}

.reddit {
    i, &:before {
        margin: 13px 0 0 12px;
    }
}

#top-bar .reddit {
    i, &:before {
        margin: 11px 0 0 10px;
    }
}

.reddit {
    &:before {
        content: "\3e";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $reddit;
    }
}

.the-team .reddit {
    background-color: $reddit;
}

.appstore {
    i, &:before {
        margin: 13px 0 0 14px;
    }
}

#top-bar .appstore {
    i, &:before {
        margin: 11px 0 0 12px;
    }
}

.appstore {
    &:before {
        content: "\41";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: lighten($grey, 22%);
    }
}

.the-team .appstore {
    background-color: lighten($grey, 22%);
}

.blogger {
    i, &:before {
        margin: 13px 0 0 15px;
        font-size: 14px !important;
    }
}

#top-bar .blogger {
    i, &:before {
        margin: 10px 0 0 13px;
        font-size: 12px !important;
    }
}

.blogger {
    &:before {
        content: "\42";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $blogger;
    }
}

.the-team .blogger {
    background-color: $blogger;
}

.dribbble {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .dribbble {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.dribbble {
    &:before {
        content: "\44";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $dribble;
    }
}

.the-team .dribbble {
    background-color: $dribble;
}

.evernote {
    i, &:before {
        margin: 13px 0 0 15px;
    }
}

#top-bar .evernote {
    i, &:before {
        margin: 11px 0 0 13px;
    }
}

.evernote {
    &:before {
        content: "\45";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $evernote;
    }
}

.the-team .evernote {
    background-color: $evernote;
}

.flickr {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .flickr {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.flickr {
    &:before {
        content: "\46";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $flickr;
    }
}

.the-team .flickr {
    background-color: $flickr;
}

.rss {
    i, &:before {
        margin: 13px 0 0 14px;
        font-size: 14px !important;
    }
}

#top-bar .rss {
    i, &:before {
        margin: 11px 0 0 12px;
        font-size: 12px !important;
    }
}

.rss {
    &:before {
        content: "\52";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $rss;
    }
}

.the-team .rss {
    background-color: $rss;
}

.youtube {
    i, &:before {
        margin: 13px 0 0 14px;
        font-size: 18px !important;
    }
}

#top-bar .youtube {
    i, &:before {
        margin: 10px 0 0 12px;
        font-size: 16px !important;
    }
}

.youtube {
    &:before {
        content: "\55";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $youtube;
    }
}

.the-team .youtube {
    background-color: $youtube;
}

.vimeo {
    i, &:before {
        margin: 13px 0 0 13px;
        font-size: 14px !important;
    }
}

#top-bar .vimeo {
    i, &:before {
        margin: 11px 0 0 11px;
        font-size: 12px !important;
    }
}

.vimeo {
    &:before {
        content: "\56";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $vimeo;
    }
}

.the-team .vimeo {
    background-color: $vimeo;
}

.xing {
    i, &:before {
        margin: 13px 0 0 14px;
    }
}

#top-bar .xing {
    i, &:before {
        margin: 11px 0 0 12px;
    }
}

.xing {
    &:before {
        content: "\58";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $xing;
    }
}

.the-team .xing {
    background-color: $xing;
}

.yahoo {
    i, &:before {
        margin: 13px 0 0 11px;
    }
}

#top-bar .yahoo {
    i, &:before {
        margin: 11px 0 0 9px;
    }
}

.yahoo {
    &:before {
        content: "\59";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $yahoo;
    }
}

.the-team .yahoo {
    background-color: $yahoo;
}

.amazon {
    i, &:before {
        margin: 13px 0 0 12px;
    }
}

#top-bar .amazon {
    i, &:before {
        margin: 11px 0 0 10px;
    }
}

.amazon {
    &:before {
        content: "\61";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $amazon;
    }
}

.the-team .amazon {
    background-color: $amazon;
}

.steam {
    i, &:before {
        margin: 13px 0 0 11px;
    }
}

#top-bar .steam {
    i, &:before {
        margin: 11px 0 0 9px;
    }
}

.steam {
    &:before {
        content: "\62";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $steam;
    }
}

.the-team .steam {
    background-color: $steam;
}

.dropbox {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .dropbox {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.dropbox {
    &:before {
        content: "\64";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $dropbox;
    }
}

.the-team .dropbox {
    background-color: $dropbox;
}

.github {
    i, &:before {
        margin: 13px 0 0 13px;
    }
}

#top-bar .github {
    i, &:before {
        margin: 11px 0 0 11px;
    }
}

.github {
    &:before {
        content: "\67";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $github;
    }
}

.the-team .github {
    background-color: $github;
}

.lastfm {
    i, &:before {
        margin: 13px 0 0 11px;
    }
}

#top-bar .lastfm {
    i, &:before {
        margin: 11px 0 0 9px;
    }
}

.lastfm {
    &:before {
        content: "\6c";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $lastfm;
    }
}

.the-team .lastfm {
    background-color: $lastfm;
}

.tumblr {
    i, &:before {
        margin: 13px 0 0 15px;
    }
}

#top-bar .tumblr {
    i, &:before {
        margin: 11px 0 0 13px;
    }
}

.tumblr {
    &:before {
        content: "\74";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $tumblr;
    }
}

.the-team .tumblr {
    background-color: $tumblr;
}

.wordpress {
    i, &:before {
        margin: 13px 0 0 14px;
    }
}

#top-bar .wordpress {
    i, &:before {
        margin: 11px 0 0 12px;
    }
}

.wordpress {
    &:before {
        content: "\77";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $steam;
    }
}

.the-team .wordpress {
    background-color: $steam;
}

.instagram {
    i, &:before {
        margin: 13px 0 0 14px;
    }
}

#top-bar .instagram {
    i, &:before {
        margin: 11px 0 0 12px;
    }
}

.instagram {
    &:before {
        content: "\f31f";
        top: 0;
        opacity: 1;
    }
    &:hover {
        &:before {
            top: 30px;
            opacity: 0;
        }
        background-color: $instagram;
    }
}

.the-team .instagram {
    background-color: $instagram;
}

/* Web Font */
@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?31771571');
    src: url('../fonts/fontello.eot?31771571#iefix') format("embedded-opentype"), url('../fonts/fontello.woff?31771571') format("woff"), url('../fonts/fontello.ttf?31771571') format("truetype"), url('../fonts/fontello.svg?31771571#fontello') format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.social-icons li i {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 16px;
    font-size: 16px;
    width: auto;
}

.icon-delicious:before {
    content: '\23';
}

/* '#' */

.icon-paypal:before {
    content: '\24';
}

/* '$' */

.icon-gplus:before {
    content: '\2b';
}

/* '+' */

.icon-stumbleupon:before {
    content: '\2f';
}

/* '/' */

.icon-fivehundredpx:before {
    content: '\30';
}

/* '0' */

.icon-pinterest:before {
    content: '\31';
}

/* '1' */

.icon-forrst:before {
    content: '\3a';
}

/* ':' */

.icon-digg:before {
    content: '\3b';
}

/* ';' */

.icon-spotify:before {
    content: '\3d';
}

/* '=' */

.icon-reddit:before {
    content: '\3e';
}

/* '&gt;' */

.icon-appstore:before {
    content: '\41';
}

/* 'A' */

.icon-blogger:before {
    content: '\42';
}

/* 'B' */

.icon-dribbble:before {
    content: '\44';
}

/* 'D' */

.icon-evernote:before {
    content: '\45';
}

/* 'E' */

.icon-flickr:before {
    content: '\46';
}

/* 'F' */

.icon-linkedin:before {
    content: '\4c';
}

/* 'L' */

.icon-rss:before {
    content: '\52';
}

/* 'R' */

.icon-twitter:before {
    content: '\54';
}

/* 'T' */

.icon-youtube:before {
    content: '\55';
}

/* 'U' */

.icon-vimeo:before {
    content: '\56';
}

/* 'V' */

.icon-xing:before {
    content: '\58';
}

/* 'X' */

.icon-yahoo:before {
    content: '\59';
}

/* 'Y' */

.icon-amazon:before {
    content: '\61';
}

/* 'a' */

.icon-steam:before {
    content: '\62';
}

/* 'b' */

.icon-dropbox:before {
    content: '\64';
}

/* 'd' */

.icon-facebook:before {
    content: '\66';
}

/* 'f' */

.icon-github:before {
    content: '\67';
}

/* 'g' */

.icon-lastfm:before {
    content: '\6c';
}

/* 'l' */

.icon-tumblr:before {
    content: '\74';
}

/* 't' */

.icon-wordpress:before {
    content: '\77';
}

/* 'w' */

.icon-linkedin-rect:before {
    content: '\f31b';
}

/* '?' */

.icon-instagram:before {
    content: '\f31f';
}

/* '?' */

/* Skill Bars
------------------------------------- */

.skill-bar {
    height: 20px;
    position: relative;
    margin: 20px 0 42px 0;
    background-color: lighten($grey, 43%);
}

.skill-title {
    display: block;
    float: left;
    position: relative;
    z-index: 5;
    color: lighten($grey, 6%);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    top: -27px;
    width: 100%;
}

span.skill-title i {
    float: right;
    position: relative;
    display: block;
    right: 0;
    color: lighten($grey, 22%);
}

.skill-bar-value {
    display: block;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: darken($grey, 3%);
    overflow: visible !important;
}

/* Skill Bars
------------------------------------- */

.info-banner {
    background: lighten($grey, 43%);
    display: block;
    padding: 24px 28px;
}

.info-content {
    float: left;
}

.info-banner {
    p {
        margin: 0;
        color: lighten($grey, 3%);
    }
    a.button {
        float: right;
        margin: 5px 0 0 0;
        font-size: 14px;
        padding: 8px 15px;
    }
    h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
    }
}

/* Notification Boxes
------------------------------------- */

#result .success {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    position: relative;
    padding: 13px 40px 13px 18px;
}

.notification {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    position: relative;
    padding: 13px 18px 13px 18px;
    p {
        margin: 0;
    }
    span {
        font-weight: 600;
    }
}

#result .success {
    background-color: $success-bg;
    color: $success;
}

.notification {
    &.success {
        background-color: $success-bg;
        color: $success;
        strong {
            background-color: $success-bg;
            color: $success;
        }
    }
    &.error {
        background-color: $error-bg;
        color: $error;
        strong {
            background-color: $error-bg;
            color: $error;
        }
    }
    &.warning {
        background-color: $warning-bg;
        color: $warning;
        strong {
            background-color: $warning-bg;
            color: $warning;
        }
    }
    &.notice {
        background-color: $notice-bg;
        color: $notice;
        strong {
            background-color: $notice-bg;
            color: $notice;
        }
    }
    strong {
        border: none !important;
    }
    &.success .close, &.error .close, &.warning .close, &.notice .close {
        padding: 0 9px;
        position: absolute;
        right: 0;
        top: 22px;
        display: block;
        height: 8px;
        width: 8px;
        cursor: pointer;
    }
    &.success .close {
        background: url(../images/alert_boxes_close_ico.png) 0 -8px no-repeat;
    }
    &.error .close {
        background: url(../images/alert_boxes_close_ico.png) 0 0 no-repeat;
    }
    &.warning .close {
        background: url(../images/alert_boxes_close_ico.png) 0 -16px no-repeat;
    }
    &.notice .close {
        background: url(../images/alert_boxes_close_ico.png) 0 -24px no-repeat;
    }
}

/* Counters
------------------------------------- */

.counter-box {
    display: block;
    background: lighten($grey, 43%);
    padding: 40px 20px 37px 20px;
    text-align: center;
    p {
        margin: 5px 0 0 0;
        padding: 0;
        color: lighten($grey, 3%);
        font-size: 18px;
        font-weight: 500;
    }
    i {
        font-size: 60px;
        margin: 0 0 15px 0;
        color: lighten($grey, 29%);
    }
}

.counter {
    display: block;
    font-size: 32px;
    font-weight: 700;
    color: darken($grey, 13%);
    line-height: 28px;
}

.counter-box.colored {
    background: darken($grey, 3%);
    p, i, .counter {
        color: $white;
    }
}

/* Happy Clients
------------------------------------- */

.sb-navigation-left-2, .sb-navigation-right-2, .sb-navigation-left-2.notclickable, .sb-navigation-right-2.notclickable {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.sb-navigation-left-2, .sb-navigation-right-2 {
    cursor: pointer;
    margin-top: 42px;
}

.sb-navigation-left-2 {
    float: left;
}

.sb-navigation-right-2 {
    float: right;
}

.sb-navigation-left-2 i, .sb-navigation-right-2 i {
    line-height: 24px;
    font-size: 24px;
    color: lighten($grey, 23%);
}

.sb-navigation-left-2:hover, .sb-navigation-right-2:hover {}

.sb-navigation-left-2.notclickable, .sb-navigation-right-2.notclickable {
    cursor: default;
}

.sb-navigation-left-2.notclickable i, .sb-navigation-right-2.notclickable i {
    color: lighten($grey, 23%);
}

.sb-navigation-left-2.alt, .sb-navigation-right-2.alt {
    top: 57%;
    margin: 0;
    position: absolute;
    height: 20px;
    width: 20px;
    z-index: 99;
}

.sb-navigation-left-2.alt {
    left: 10px;
}

.sb-navigation-right-2.alt {
    right: 0;
}

.happy-clients-photo img {
    margin: 0 auto;
    display: block;
    margin-bottom: 11px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.happy-clients-cite {
    font-size: 24px;
    font-weight: 300;
    color: lighten($grey, 6%);
    width: 75%;
    margin: 0 auto;
    display: block;
    text-align: center;
    line-height: 36px;
    margin-bottom: 7px;
}

.happy-clients-author {
    font-size: 18px;
    font-weight: 500;
    color: darken($grey, 3%);
    margin: 0 auto;
    display: block;
    text-align: center;
    line-height: 32px;
}

/* Additional Menu
------------------------------------- */

.additional-menu {
    display: block;
    float: right;
    margin: 18px 0 20px 0;
    ul li {
        display: inline;
        padding: 0 11px;
        float: left;
        &:first-child {
            background: none;
        }
        &:last-child {
            padding-right: 0;
        }
        a {
            font-size: 13px;
            color: $virusmask-dark;
            span {
                color: lighten($grey, 20%);
            }
            &:hover {
                color: $virusmask-main;
                span {
                    color: darken($grey, 3%);
                }
            }
        }
    }
}

/* Search
------------------------------------- */
::-webkit-input-placeholder { /* Edge */
    color: #999999;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999999;
}

::placeholder {
    color: #999999;
}

.search-backdrop{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 199;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    pointer-events: none;
    &.active{
        opacity: 1;
        pointer-events: initial;
    }
}

.mobile-search,
.header-search{
    @include screen-sm-up{
        padding-top: 20px;
    }
    position: relative;
    .top-search {
        .search-inputs-container{
            display:flex;
            flex-direction: row;
            .form-row{
                margin-bottom: 0;
            }
            .search-field-container{
                flex-grow: 1;
                input.search-field {
                    width:100%;
                    height: 50px;
                    font-size: 16px;
                    padding:10px;
                    background-color: #f0f2f5;
                    font-weight: 400;
                    color: $dark-grey2;
                    border-left: 1px solid #cecece;
                    border-top: 1px solid #cecece;
                    border-bottom: 1px solid #cecece;
                    border-right: none;
                }
                .has-error{
                    .help-block{
                        line-height: 19px;
                        font-size: 14px;
                    }
                }
            }
            .search-button-container{
                width: 50px;
                button.search-button{
                    width:100%;
                    height: 50px;
                    text-transform: uppercase;
                    -webkit-transition: background-color 0.2s ease-in-out;
                    -moz-transition: background-color 0.2s ease-in-out;
                    -ms-transition: background-color 0.2s ease-in-out;
                    -o-transition: background-color 0.2s ease-in-out;
                    transition: background-color 0.2s ease-in-out;
                    background-color: #f0f2f5;
                    border-right: 1px solid #cecece;
                    border-top: 1px solid #cecece;
                    border-bottom: 1px solid #cecece;
                    font-size: 26px;
                    color: $dark-grey2;
                    padding:0px;
                    &:hover {
                        background-color: #f0f2f5;
                    }
                }
            }
        }
        display: block;

        .search-typeahead-list {
            position: absolute;
            background-color: $white;
            min-height: 50px;
            top:49px;
            width:100%;
            z-index: 201;
            display: none;
            flex-direction: row;
            border: 1px solid #cecece;
            .search-label-container{
                padding: 5px 0;
                border-right: 1px solid #cecece;
                width:auto;
                @include screen-xs {
                    display:none;
                }
                ul{

                }
                .search-label{
                    color: $dark-grey2;
                    display: block;
                    padding: 8px 15px;
                    span.hl{
                        color: $light-blue-hover;
                        font-weight: 600;
                    }
                    &.active,
                    &:hover{
                        background-color: #dcf9ff;
                    }
                }
            }
            .search-product-container{
                &.no-prod{
                    width:100%;
                }
                .search-product {
                    color: $dark-grey2;
                    span.hl{
                        color: $light-blue-hover;
                        font-weight: 600;
                    }
                    display: flex;
                    flex-direction: row;
                    align-content: stretch;
                    border-bottom: 1px solid #cecece;

                    &.active,
                    &:hover{
                        background-color: #dcf9ff;
                    }
                    .search-product-image-container {
                        width:75px;
                        padding-top: 15px;
                        padding-left: 15px;
                        padding-bottom: 15px;
                    }
                    .search-details-container{
                        padding: 15px 15px 15px 10px;
                        width: 100%;
                        .search-detail-price{
                            margin-top: 5px;
                            font-weight: 600;
                        }
                    }
                }
                .search-more-product{
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 13px;
                    display: block;
                    background-color: $backgroundColor;
                    padding:15px 0;
                    font-weight: bold;
                    color:$dark-grey2;
                    &.active{
                        background-color: #dcf9ff;
                    }
                }
                li {
                    &:last-child{
                        .search-product{
                            border-bottom: 0;
                        }
                    }
                }
                @include screen-xs {
                    width:100%;
                }
            }
        }
    }
}
.header-search{
    @include screen-xs{
        .search-inputs-wrapper{
            display: none;
        }
    }
}
.mobile-search{
    position: absolute;
    top: -200vh;
    /*height: 100vh;*/
    left:0;
    right: 0;
    background-color: $white;
    z-index: 200;
    -webkit-transition: top 0.3s ease-in-out;
    -moz-transition: top 0.3s ease-in-out;
    -ms-transition: top 0.3s ease-in-out;
    -o-transition: top 0.3s ease-in-out;
    transition: top 0.3s ease-in-out;
    &.active {
        top:0;
        @include screen-sm-up{
            top: -200vh;
        }
    }

    .mobile-search-header {
        height: 54px;
        background-color: $dark-blue;
        color:$white;
        text-align: center;
        font-size: 24px;
        padding: 16px;
    }
    .mobile-search-close{
        cursor:pointer;
    }
    .mobile-search-content {
    }
}

.apply-coupon input {
    font-size: 13px;
    width: 192px;
    padding: 10px 13px 10px 13px;
    float: left;
    background-color: $white;
    display: inline-block;
    margin: 0;
    border: 0;
    &:focus {
        background-color: $white;
        margin: 0;
        border: 0;
    }
}

.apply-coupon a {
    display: block;
    float: left;
}

.menu-right{
    @include screen-xs {
        padding: 0 10px;
    }
    > div.empty-cart-container{
        float:right;
    }

    @include screen-sm-up {
        > div.not-empty-cart-container {
            div {
                float:right;
            }
        }
    }
    .personal-menu-guest{
        @include screen-xs {
            float:left;
            padding: 10px 0;
            font-size: 16px;
            a {
                color: $dark-grey2;
                &:hover{
                    color:$light-blue-hover;
                }
            }
        }
    }
    @include screen-max-400 {
        .header-cart-amount{
            display: none;
        }
    }
    .personal-menu{
        @include screen-xs {
            float:left;
        }
        .dropdown{
            padding: 29px 5px;
            > span{
                font-size: 30px;
                cursor: pointer;
            }
            .submenu{
                right: 0;
                @include screen-xs {
                    right: initial;
                    left: 0;
                }
                left:initial;
                .dropdown{
                    padding: 0;
                    position: relative;
                }
                .submenu{
                    padding-top: 5px;
                    padding-bottom: 5px;
                    right: -240px;
                    left:initial;
                }
            }
            @include screen-xs{
                padding: 7px;
                > span{
                    font-size: 30px;
                }
            }
        }
    }
    .header-cart{
        @include screen-xs{
            float:right;
        }
    }
    .mobile-search-container{
        @include screen-xs{
            float:right;
        }
    }
}
/* Cart
------------------------------------- */

#cart {
    float: right;
    display: block;
    margin: 0 0 0 10px;
    position: relative;
    z-index: 100;

    &.updating {
        opacity: 0.5;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }
}
#cart-sidebar{
    position: fixed;
    left:0;
    top:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 400;
    opacity: 0;
    pointer-events: none;
    &.active{
        opacity: 1;
        pointer-events: initial;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }

}

.cart-list {
    position: absolute;
    right: -340px;
    background: $white;
    z-index: 9999;
    width: 340px;
    &.animated{
        -webkit-transition: right 0.3s ease-in-out;
        -moz-transition: right 0.3s ease-in-out;
        -o-transition: right 0.3s ease-in-out;
        -ms-transition: right 0.3s ease-in-out;
        transition: right 0.3s ease-in-out;
    }
    &.active {
        display: block;
        opacity: 1;
        visibility: visible;
        right: 0;
    }
    @include screen-phone{
        width: 100vw;
        right: -100vw;
    }
}

/* Cart Button */

.cart-btn a.button {
    padding: 28px 5px;
    float: left;
    font-weight: 400;
    color: $dark-grey2;
    font-size: 18px;
    @include screen-sm-down{
        font-size: 15px;
    }
    background: transparent !important;
    span{
        font-size: 30px;
        margin-right: 10px;
        position: relative;
    }
    i.badge{
        background-color: #fc461c;
        color:$white;
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 11px;
        height: 15px;
        border-radius: 50%;
        display: block;
        line-height: 14px;
        padding: 0 5px;
    }
    &.hovered, &:hover {
    }
    @include screen-xs{
        padding: 10px;
        font-size: 18px;
        span{
            font-size: 26px;
            vertical-align: text-top;
        }
        i.badge{
            top:-8px;
            right: -8px;
        }
    }
}

.load-cart-widget-container{
    position: absolute;
    right: 0;
    top:0;
}

.load-cart-popup-container{
    position: absolute;
    background-color: $white;
    width: 300px;
    height: 225px;
    top:20px;
    left:0;
    right: 0;
    z-index: 300;
    margin-left: auto;
    margin-right: auto;
    padding:20px;
}

.load-cart-popup-container-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 199;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    pointer-events: none;
    &.active{
        opacity: 1;
        pointer-events: initial;
    }
}

/* Cart List */

#cart ul {
    padding: 16px;
    li {
        border-bottom: 1px solid lighten($grey, 37%);
        padding: 13px 0;
        display: block;
        &:last-child {
            border-bottom: none;
            padding: 13px 0 0 0;
        }
        &:first-child {
            padding: 0 0 13px 0;
        }
        a {
            display: block;
            color: darken($grey, 13%);
            font-size: 14px;
            font-weight: 600;
        }
        span {
            display: block;
            float: none;
            padding: 0;
            font-size: 13px;
            color: lighten($grey, 10%);
            line-height: 18px;
        }
        a img {
            display: inline-block;
            float: left;
            padding: 0;
            width: 55px;
            height: auto;
            margin: 0 15px 0 0;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }
    }
}
.cart-items-container{
    height: calc(100vh - 217px);
    //@include screen-xs{
    //    height: calc(100vh - 277px);
    //}
    overflow: auto;
    > ul > li{
        padding: 15px;
        border-bottom: 2px solid #e6e6e6;
        div.cart-item-product{
            display:flex;
            flex-direction: row;
            div.product-image-cont{
                width: 60px;
                margin-right: 10px;
            }
            div.product-detail-cont{
                width: 100%;
                padding: 0 8px;
                a{
                    font-size: 14px;
                    color: $dark-grey2;
                    font-weight: 400;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                div{
                    line-height: 24px;
                    font-size: 14px;
                    span.quantity{
                    }
                    span.amount{
                        font-weight: 700;;
                    }
                }
            }
            div.product-delete-cont{
                font-size: 20px;
                cursor:pointer;
            }
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}
.cart-amount {
    text-transform: uppercase;
    background-color: $dark-blue;
    text-align: center;
    font-weight: 400;
    font-size: 16px;

    button {
        background-color: transparent;
        float: left;
        font-size: 24px;
        padding: 12px 16px;
        &:hover{
            background-color: transparent;
        }
    }
    .cart-sidebar-title{
        padding-top:12px;
        display: inline-block;
        margin-left: -40px;
    }
}

.cart-list .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid lighten($grey, 40%);
    position: absolute;
    top: -5px;
    left: 230px;
}

.cart-amount span {
    color: $white !important;
}

.cart-buttons-border-top{
    //border-top: 1px solid #cccccc;
    padding-top: 1px;
    height: 217px;
}

.cart-buttons-summary{
    font-weight: 700;
    text-transform: uppercase;
    color: #1f1f1e;
    padding: 10px 0 20px 0;
    .cart-buttons-summary-text{
        font-size: 16px;
    }

    .cart-buttons-summary-amount{
        font-size: 20px;
        float: right;
        font-weight: 700;
    }
}
.cart-buttons {

    border-top: 1px solid #cccccc;
    padding: 15px 16px 10px 16px;
    position: fixed;
    bottom:0;
    right: -340px;
    width:340px;
    background-color: $white;
    @include screen-phone{
        width:100%;
    }
    a {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        color: $white;
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 7px 0;
        margin: 0 0 5px 0;
        background-color: darken($grey, 3%);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        position: relative;
        &.continue-shopping,
        &.checkout {
            background-color: $light-blue;
            &:hover{
                color:$white;
            }
        }
        &.view-cart {
            background-color: $dark-green;
            &:hover{
                color:$white;
            }
        }
        &.view-cart:before, &.checkout:before, &.continue-shopping:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: none;
            -webkit-transition: background-color 0.2s ease-in-out;
            -moz-transition: background-color 0.2s ease-in-out;
            -ms-transition: background-color 0.2s ease-in-out;
            -o-transition: background-color 0.2s ease-in-out;
            transition: background-color 0.2s ease-in-out;
        }
        &.view-cart:hover:before, &.checkout:hover:before, &.continue-shopping:hover:before {
            background-color: darken($light-blue, 10%);
            color:$white;
        }
        span {
            position: relative;
            z-index: 200;
        }
    }
}
.cart-list {
    &.animated {
        .cart-buttons {
            -webkit-transition: right 0.3s ease-in-out;
            -moz-transition: right 0.3s ease-in-out;
            -o-transition: right 0.3s ease-in-out;
            -ms-transition: right 0.3s ease-in-out;
            transition: right 0.3s ease-in-out;
        }
    }
    &.active {
        .cart-buttons{
            right: 0;
        }
    }
}
ul.cart-subproducts {
    li {
        padding-left: 13px;
    }
}

.cart-result {
    font-size: 16px;
    font-weight: 600;

    @include screen-xs {
        margin: 0;
    }

    .message {
        margin: 10px 10px 10px 0;
        float: left;
    }

    .cart-btns {
        margin-top: 0;
        float: left;

        @media (min-width: 1390px) {
            float: right;
        }
        @include screen-sm-down {

            width: 100%;
        }

        .button {
            &:not(:first-child) {
                margin-left: 5px;
            }
            padding: 7px 14px;
            margin: 0;

            @include screen-sm-down {
                &:not(:first-child) {
                    margin-top: 10px;
                }
                width: 100%;
                margin-left:0 !important;
                text-align: center;
            }

        }
    }
}

.cart-purchase {
    float: right;
    clear: both;
}



/* Checkbox */

input.input-checkbox {
    width: 20px;
    float: left;
    position: relative;
    width: auto !important;
}

label.checkbox {
    margin: -5px 0 0 5px;
    font-weight: 600;
    float: left;
    cursor: pointer;
}

.shippping-checkbox {
    display: block;
    margin-top: 15px;
}

.checkout-content {
    label abbr {
        color: $red;
    }
    select {
        overflow: hidden;
        background: url(../images/down_arrow_select.png) no-repeat right lighten($grey, 43%);
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
}

.checkout-summary select {
    overflow: hidden;
    background: url(../images/down_arrow_select.png) no-repeat right lighten($grey, 43%);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

@-moz-document url-prefix() {
    .checkout-content select, .checkout-summary select {
        //background-image: none;
        padding-right: 20px;
    }
}


/* Address Review & Delivery List */

ul {
    &.address-review {
        margin: 10px 0 24px 0;
        li {
            line-height: 21px;
        }
        &.summary li {
            margin: 0 0 10px 0;
        }
        &.delivery {
            margin: 10px 0 18px 0;
        }
        li strong {
            margin-bottom: 4px;
            display: inline-block;
        }
    }
    &.delivery-options {
        li strong {
            margin-bottom: 4px;
            display: inline-block;
            margin-bottom: 7px;
            color: lighten($grey, 3%);
            font-weight: 500;
        }
        margin: 20px 0 24px 0;
    }
}

.delivery-options li {
    input {
        margin: 2px 0 0 0;
        position: absolute;
        left: 15px;
    }
    label {
        margin: 0 0 0 21px;
        color: $dark-grey;
        span {
            color: lighten($grey, 3%);
            margin-left: 4px;
            font-weight: 500;
        }
    }
}

.delivery-summary {
    color: lighten($grey, 3%);
    margin-left: 4px;
    font-weight: 500;
}

.delivery-options li label span span, .delivery-summary span {
    color: lighten($grey, 29%);
    font-size: 11px;
    display: inline-block;
    position: relative;
    top: -2px;
}

.delivery-option {
    display: block;
    margin-top: 5px;
    float: left;
}

/* Checkout Cart */

.qty-checkout {
    text-align: center;
}

.checkout td {
    line-height: 20px;
}

.checkout-section.cart {
    padding: 12px 23px 14px 23px;
    background: $dark-grey;
    color: $white;
    margin: 0;
}

.checkout-subtotal {
    margin: 0 15px 0 5px;
    color: darken($grey, 3%);
    float: right;
    &.summary {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid lighten($grey, 35%);
        width: 94.5%;
        position: relative;
        text-align: right;
    }
    span {
        color: $dark-grey;
        margin-left: 5px;
    }
}

/* Payment & Order Review */

.payment-icons.checkout {
    float: none;
    top: 0;
    position: relative;
    display: block;
    li {
        display: inline-block;
        float: left;
        img {
            margin: 0 4px 0 0;
            border: 1px solid lighten($grey, 35%);
            padding: 3px;
            cursor: pointer;
        }
    }
}

.credit-card-fields {
    span {
        display: inline;
        float: left;
        margin-right: 15px;
    }
    input {
        width: 305px;
        min-width: 100px;
    }
    select {
        min-width: 100px;
    }
}

.order-totals-spacer {
    border-top: 1px solid lighten($grey, 35%);
    padding: 10px 0;
}

/* My Account
-------------------------------------- */

.centered {
    margin: 0 auto !important;
    float: none !important;
    display: block !important;
}

.form-row-wide textarea,
.form-row-wide select,
.form-row-wide input {
    width: 100%;
}

.rememberme {
    display: block;
    margin-top: 10px;
    cursor: pointer;
    input {
        margin: 0 2px 0 0;
        top: 1px;
        display: inline-block;
    }
}

/* ------------------------------------------------------------------- */
/* Navigation
---------------------------------------------------------------------- */

@include screen-sm-up{
    .menu {
        position: relative;
        padding: 0;
        list-style: none;
        float: left;
        width: 100%;
        max-height: 54px;
        z-index: 99;
        .mega {
            position: absolute;
            display: none;
            top: 100%;
            left: 0;
        }
        > li {
            float: left;
        }
        li {
            &:hover > .mega, &.sfHover > .mega {
                display: block;
            }
        }
        > li {
            > i,
            > a {
                font-size: 16px;
                cursor: pointer;
                display: inline-block;
                position: relative;
                color: $dark-grey2;
                font-weight: 700;
                padding: 18px 10px;
                @include screen-sm-down {
                    font-size: 13px;
                }
                &:hover{
                    color: $white;
                }
                &.current{
                    color: $white;
                }
                &.red-link{
                    color:$red;
                    &.current{
                        color:$white;
                    }
                }
            }
            .current {
                background-color:  $light-blue;
            }
            &:hover .current, &.sfHover .current {
                background-color:  $light-blue;
            }
        }
        > li {
            &:hover, &.sfHover {
                background-color:  $light-blue;
                i,
                a{
                    color: $white;
                    &.with-ul:after{
                        border-top-color: $dark-grey;
                    }
                }
            }
            &.dropdown li.sfHover {
                background-color:  $light-blue;
                i,
                a{
                    color: $dark-grey;
                }
            }
        }

    }
}
/* Current Page */

.current {
    -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out;
    -ms-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
}

.menu > li {
    -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out;
    -ms-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
    &:hover, &.sfHover {
        -webkit-transition: background-color 0.15s ease-in-out;
        -moz-transition: background-color 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out;
        -ms-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
    }
}

/* Mega Menu */

.mega {
    background-color: lighten($grey, 48%);
    padding: 0;
    box-sizing: border-box;
    border-bottom: 1px solid lighten($grey, 39%);
    font-size: 13px;
    a {
        color: lighten($grey, 3%);
        padding: 2px 0;
        display: block;
        margin-left: 12px;
        line-height: 19px;
        -webkit-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        -ms-transition: all 0.15s ease-in-out;
        -o-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        &:hover {
            color: darken($grey, 3%);
        }
    }
}

.mega-headline {
    background: lighten($grey, 41%);
    color: darken($grey, 9%);
    font-weight: 600;
    display: block;
    padding: 8px 12px;
    margin-bottom: 8px;
}

.mega ul li p {
    padding: 0 12px;
    a {
        color: darken($grey, 3%);
        text-decoration: underline;
        display: inline-block;
        padding: 0;
        margin: 0;
        &:hover {
            color: $grey;
        }
    }
}

/* Mega Menu Grid */

.mega-container {
    margin-left: auto;
    margin-right: auto;
    width: 1136px;
    padding: 30px 22px;
}

.one-fourth-column, .one-fourth-columns, .one-column, .one-columns, .two-columns, .three-columns, .four-columns, .five-columns, .six-columns {
    display: inline-block;
    float: left;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
}

.mega-container {
    .one-column, .one-columns {
        width: 173px;
    }
    .two-columns {
        width: 362px;
    }
    .one-fourth-column, .one-fourth-columns {
        width: 267px;
    }
    .three-columns {
        width: 551px;
    }
    .four-columns {
        width: 740px;
    }
    .five-columns {
        width: 929px;
    }
    .six-columns {
        width: 1118px;
    }
}

/* Smaller than standard 1180 (devices and browsers) */
@media only screen and (min-width: 960px) and (max-width: 1389px) {
    .mega-container {
        margin-left: auto;
        margin-right: auto;
        width: 894px;
        .one-column, .one-columns {
            width: 133px;
        }
        .two-columns {
            width: 282px;
        }
        .one-fourth-column, .one-fourth-columns {
            width: 207px;
        }
        .three-columns {
            width: 431px;
        }
        .four-columns {
            width: 580px;
        }
        .five-columns {
            width: 729px;
        }
        .six-columns {
            width: 878px;
        }
    }
}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
    #logo{
        padding: 0 10px;
        margin-right: 0 !important;
    }
    .container-md {
        width: 100%;
        .sixteen.columns{
            width: 98%;
        }
        .twelve.columns{
            width:69%;
            margin-right: 0;
        }
        .seven.columns{
            margin-right: 0;
            margin-left: 0;
            width:42%;
        }
        .five.columns{
            margin-right: 0;
            margin-left: 0;
            width:34%;
        }
        .four.columns{
            margin-left: 0;
            margin-right: 0;
            width:24%;
            &.float{
                float:right;
            }
        }
    }
    .mega-container {
        margin-left: auto;
        margin-right: auto;
        width: 702px;
        .one-column {
            width: 101px;
        }
        .two-columns {
            width: 218px;
        }
        .one-fourth-column {
            width: 159px;
        }
        .three-columns {
            width: 335px;
        }
        .four-columns {
            width: 452px;
        }
        .five-columns {
            width: 569px;
        }
        .six-columns {
            width: 686px;
        }
    }
}

/* Basic Dropdown */

li.dropdown {
    position: relative;
}

.dropdown ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    min-width: 240px;
    padding: 20px 0;
}

.dropdown {
    &.language {
        a {
            &:focus,
            &:active,
            &:hover{
                text-decoration: none !important;
            }
        }
        ul{
            right: 0;
            left: initial;
            z-index: 101;
            min-width: 100px;
            padding: 0;
            li {
                a {
                    min-width: 100px;
                    padding: 10px;
                }
            }
        }
    }
}

li.dropdown ul li {
    position: relative;
    ul {
        position: absolute;
        display: none;
        top: 0;
        left: 200px;
        z-index: 100;
        li a {
            display: block;
            min-width: 240px;
        }
    }
    a {
        display: block;
        min-width: 240px;
        font-size: 14px;
        line-height: 18px;
        padding: 10px 30px;
        transition: all 0.15s ease-in-out;
        color: $dark-grey2 !important;
        &:hover {
            background-color: #ddf9ff !important;
            color: $dark-grey2;
        }
    }
}

/* Menu Arrows **/

.arrows {
    .with-ul {
        padding-right: 32px;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 18px;
            margin-top: -2px;
            height: 0;
            width: 0;
            border: 4px solid transparent;
            border-top-color: $white;
            /* edit this to suit design (no rgba in IE8) */
            border-top-color: rgba(255, 255, 255, 0.5);
            -webkit-transition: all 0.1s ease-in-out;
            -moz-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            -ms-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
        }
    }
    > li.dropdown ul li {
        .with-ul:after, &.sfHover .with-ul:after {
            border-left-color: rgba(0, 0, 0, 0.2);
            border-top-color: rgba(255, 255, 255, 0);
            right: 12px;
            margin-top: -4px;
        }
    }
}

.reason-why-back-drop {
    position: absolute;
    top: 138px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 199;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    pointer-events: none;
    &.active{
        opacity: 1;
        pointer-events: initial;
    }
    @media (max-width: 990px) {
        top:162px;
    }
}

.reason-why-container{
    position: relative;
    padding: 19px 20px 19px 20px;
    .reason-why-text {
        font-size: 16px;
        .reason-why-text-bold {
            font-weight: 600;
        }
    }
    .reason-why-dropdown-container {
        display:none;
        position: absolute;
        background: $white;
        top:100%;
        right: 0;
        padding: 20px;
        z-index: 200;
        box-shadow: 0 0 4px 1px rgba(200,200,200,0.2);
        width: 350px;

        .reason-why-separator {
            border-bottom: 1px solid $light-grey;
            padding-bottom: 10px;
            padding-top: 5px;
        }
        .reason-why-reasons {
            font-size: 16px;
            font-weight: 400;
            padding-top: 15px;
        }
        .reason-why-reasons-list {
            font-weight: 300;
            li{
                list-style: none;
                padding:8px 0;
                font-size: 15px;
                &::before{
                    content: '';
                    display: inline-block;
                    height: 18px;
                    width: 18px;
                    margin-right: 15px;
                    background-image: url("/images/ok-icon2.svg");
                    vertical-align: sub;
                }
            }
        }
    }

    &:hover{
        background-color: $virusmask-main;
        color:$white;

        .reason-why-dropdown-container{
            display: block;
            color:$dark-grey;
        }
    }
}

.reason-why-auth{
    margin-bottom: 20px;
    p{
        font-size: 16px;
        font-weight: 600;
    }
    li{
        list-style: none;
        padding:8px 0;
        &::before{
            content: '';
            display: inline-block;
            height: 18px;
            width: 18px;
            margin-right: 15px;
            background-image: url("/images/ok-icon2.svg");
            vertical-align: sub;
        }
    }
}

/* =================================================================== */
/* Mobile Navigation
====================================================================== */

#jPanelMenu-menu {
    display: none;
    background-color: $white;
    i.current,
    a.current {
        //background: darken($grey, 3%) !important;
        &:after {
            display: none;
        }
    }
    .mobile-menu-jpanel {
        .new-label {
            top: 9px;
            right: 10px;
        }
    }
    .with-ul {
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 18px;
            margin-top: -2px;
            height: 0;
            width: 0;
            border: 4px solid transparent;
            border-top-color: $white;
            /* edit this to suit design (no rgba in IE8) */
            border-top-color: $dark-grey;
            -webkit-transition: all 0.1s ease-in-out;
            -moz-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            -ms-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
        }
    }
    li {
        i,
        a {
            cursor:pointer;
            display: block;
            padding: 12px 20px 12px 10px !important;
            margin-left: 10px;
            background-color: $white;
            -webkit-transition: all 0.1s ease-in-out;
            -moz-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            -ms-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
            font-weight: 600;
            outline: none;
            text-transform: uppercase;
            color: $dark-grey;
            border-bottom: 1px solid $light-grey;
            position: relative;
            &:hover {
                color: $virusmask-main !important;
            }
        }
        li a {
            background-color: $dark-grey;
            padding-left: 30px !important;
            text-transform: none;
            font-weight: 500;
        }
    }
    .one-fourth-column, .one-column, .two-columns, .three-columns, .four-columns, .five-columns, .six-columns {
        display: inline-block;
        float: left;
        position: relative;
        margin: 0;
        width: 100%;
    }
    .hidden-on-mobile, .mega-headline {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .additional-menu {
            &.hamburger{
                display:none;
            }
        }
}
@media only screen and (max-width: 767px) {
    .container {
        &.container-md {
            width:100%;
        }
    }
    .menu {
        max-height: none;
        padding: 10px;
    }
    #responsive {
        display: none;
    }
    .additional-menu {
        &.no-hamburger{
            display:none;
        }
    }
    #jPanelMenu-menu {
        display: block;
        a.current{
            color: $dark-grey;
        }
        z-index: 2000;
        .menu-logo{
            background-color: $dark-blue;
            padding: 9px 10px;
        }

        .additional-menu {
            &.hidden-xs{
                display:block !important;
            }
            margin: 0;
            padding: 0;
            ul{
                margin: 0;
                li {
                    padding: 0;
                    margin: 0;
                    border:0;
                    a{
                        margin: 0;
                    }
                }
            }
        }
    }

    a.menu-trigger {
        color: $white;
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        float: left;
        background: $dark-blue;
        position: relative;
        width: 100%;
        margin: 0 0 20px 0;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        height: 54px;
        text-align: center;
        i {
            padding: 20px 21px;
            margin: 0 10px 0 0;
            background: rgba(0, 0, 0, 0.08);
            font-size: 14px;
            font-weight: 500;
            position: absolute;
            left:0;
        }
        &:hover {
            background: $light-blue-hover;
        }
        .menu-trigger-logo{
            margin-top: 6px;
            background: url("/images/logo-virusmaszk-white.svg") no-repeat;
            display: inline-block;
            height: 40px;
            width: 180px;

        }
        @include screen-sm-down{
            margin-bottom: 0;
        }
    }
    .jPanel-blocker{
        background: rgba(3,2,1,0.5);
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1800;
        opacity: 0;
        transition: opacity 0.5s ease-in;
    }
    body[data-menu-position="open"] {
        overflow: hidden;
        .jPanelMenu-panel {
            overflow: hidden;
        }
        .jPanel-blocker{
            display: block;
            opacity: 1;
        }
    }
}

/* ------------------------------------------------------------------- */
/* Miscellaneous
---------------------------------------------------------------------- */

/* Headline
------------------------------------- */

.headline {
    &.w-100 {
        width: 100%;
    }
    .d-inline {
        display: inline;
    }
    .headline-link {
        font-size: 14px !important;
        line-height: 34px !important;
        font-weight: 700;
        @include screen-xs{
            float: left !important;
        }
    }
    font-size: 20px;
    padding-right: 10px;
    z-index: 5;
    position: relative;
    text-transform: uppercase;
    color: $dark-grey2;
    border-bottom: 2px solid $light-blue;
    padding-bottom: 20px;
    display: inline-block;
    a {
        color: $dark-grey2;
        &:hover{
            color:$light-blue-hover;
        }
    }

}
h1,h2,h3,h4,h5,h5{
    &.headline {
        line-height: 30px !important;
    }
}

.line {
    border-top: 1px solid #cccccc;
    width: 100%;
    height: 1px;
    display: block;
    &.simple-line {
        margin: 5px 0 0 0;
    }
}

/* Woo Advanced Search
------------------------------------- */

.woo-search {
    padding: 155px 40px;
    margin-bottom: 40px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-position: center;
    background-size: cover;
    background-image: url('../images/adv-search.jpg');
}

.woo-search-main {
    input[type="text"] {
        display: inline-block;
        width: 572px;
        font-weight: 500;
        background: $white;
        float: left;
        font-size: 20px;
        border: 0;
        padding: 0 19px;
        height: 65px;
        line-height: 32px;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    label {
        color: $white;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 15px;
    }
}

.woo-search .search-form {
    width: 640px;
    margin: 0 auto;
}

.woo-search-main button {
    background: $dark-grey;
    border: 0;
    color: $white;
    cursor: pointer;
    margin: 0;
    -webkit-transition: background 0.2s ease-out;
    -moz-transition: background 0.2s ease-out;
    -o-transition: background 0.2s ease-out;
    transition: background 0.2s ease-out;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 22px;
    max-height: 65px;
    width: 65px;
    line-height: 20px;
    &:hover {
        background: $black;
    }
    i {
        font-size: 20px;
        line-height: 20px;
        height: 25px;
    }
}

.woo-search .advanced-search-btn {
    text-transform: uppercase;
    padding: 0 0 0 18px;
    margin-top: 10px;
    font-size: 14px;
    &:hover {
        background: $dark-grey;
    }
    i {
        background: rgba(0, 0, 0, 0.1);
        padding: 17px 18px 15px 18px;
        margin: 0;
        margin-left: 15px;
        height: 14px;
    }
}

.woo-search-elements {
    margin-top: -7px;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    z-index: 9999;
    width: 612px;
    border-bottom: 1px solid lighten($grey, 39%);
    background: $white;
    padding: 20px 10px 10px 10px;
    opacity: 0;
    visibility: visible;
    transition: all 0.4s;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    button {
        background: lighten($grey, 43%);
        padding: 20px;
        outline: none;
    }
}

.woo-search .advanced-search-btn {
    text-transform: uppercase;
    padding: 0 0 0 18px;
    margin-top: 10px;
    font-size: 14px;
    &:hover {
        background: $dark-grey;
    }
    i {
        background: rgba(0, 0, 0, 0.1);
        padding: 17px 18px 15px 18px;
        margin: 0;
        margin-left: 15px;
        height: 14px;
    }
}

.woo-search-elements {
    .variables {
        .customSelect {
            flex: 3;
            margin: 0 10px;
        }
        display: flex;
    }
    #price-range {
        padding: 0 10px;
        margin-bottom: 40px;
    }
}

/* Parallax
------------------------------------- */

.parallax-banner {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 40px;
    height: 200px;
    opacity: 0;
    img {
        position: absolute;
    }
}

.parallax-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.45);
}

.parallax-title {
    position: relative;
    z-index: 10;
    font-size: 40px;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    top: 0;
    left: 0;
    text-align: center;
    span {
        display: block;
        padding: 15px 0 0 0;
        font-size: 28px;
        font-weight: 300;
    }
}

/* Parallax Titlebar
------------------------------------- */

.parallax-titlebar {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 38px;
    height: 200px;
    img {
        position: absolute;
    }
    h2 {
        position: relative;
        z-index: 10;
        font-size: 32px;
        color: $white;
        font-weight: 700;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        left: 55px;
    }
    span {
        font-size: 22px;
        color: $white;
        font-weight: 300;
        margin-top: 5px;
        display: block;
    }
}

.parallax-content {
    position: relative;
    height: 100%;
}

/* Titlebar
------------------------------------- */

.titlebar {
    position: relative;
    h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 6px;
        float: left;
        width: 100%;
    }
    span {
        font-weight: 300;
        color: lighten($grey, 14%);
    }
    #breadcrumbs {
        ul {
            float: left;
            margin: 0;
            right: 0;
        }
        a {
            color: $dark-grey;
        }
        ul li {
            color: $dark-grey2;
            a {
                color: $dark-grey2;
                &:hover {
                    color: $dark-grey2;
                }
            }
            &:last-child a {
                color: $dark-grey2;
            }
            &:after {
                color: $dark-grey2;
            }
        }
        .breadcrumb{
            font-size: 14px;
            font-weight: 400;
            padding: 15px 0;
            @include screen-sm-down{
                padding: 10px 0;
            };
        }
    }
}

/* Breadcrumbs
------------------------------------- */

#breadcrumbs ul {
    float: right;
    font-size: 13px;
    margin-top: 27px;
    font-weight: 500;
    position: relative;
    z-index: 10;
    right: 55px;
    li {
        display: inline-block;
        color: $white;
        margin: 0 5px 0 5px;
        &:after {
            content: "\f3d1";
            font-family: "Ionicons";
            font-size: 10px;
            color: $white;
            margin: 0 0 0 9px;
        }
        &:last-child:after {
            content: "";
            margin: 0;
        }
        a {
            color: $white;
            &:hover {
                color: $white;
            }
        }
        &:last-child a {
            color: $white;
        }
        &:first-child {
            background: none;
        }
        &:last-child {
            padding: 0;
            background: none;
        }
    }
}

/* Full Width Styles
------------------------------------- */

.fullwidth {
    .parallax-content {
        margin: 0 auto;
    }
    .parallax-banner.fullwidth-element, .parallax-titlebar.fullwidth-element, .parallax-banner img, .parallax-titlebar img {
        width: 100%;
    }
    .parallax-content {
        h2 {
            left: 0;
        }
        #breadcrumbs ul {
            left: 0;
            right: 0;
        }
    }
}

/* Back to Top
------------------------------------- */

#backtotop {
    position: fixed;
    display: none;
    bottom: 20px;
    z-index: 10000;
    a {
        text-decoration: none;
        border: 0 none;
        display: block;
        width: 45px;
        height: 45px;
        background-color: rgba(0, 0, 0, 0.25);
        color: $white;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        &:after {
            content: "";
            font-family: "FontAwesome";
            position: relative;
            display: block;
            top: 10px;
            left: 15px;
        }
        &:hover {
            background-color: darken($grey, 3%);
        }
    }
    @include screen-xs{
        right: initial;
        left: 20px;
    }
}
@include screen-xs {
    body[data-menu-position="open"]{
        #backtotop{
            display: none !important;
        }
    }
}
/* Pagination
------------------------------------- */

.pagination {
    margin: 0;
    text-align: center;
    overflow: hidden;
}

.pagination-next-prev {
    position: relative;
    top: -36px;
}

.pagination ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    a {
        padding: 9px 14px;
        border-bottom: none;
        display: inline-block;
        color: $white;
        background-color: lighten($grey, 22%);
        font-weight: 700;
        margin: 0;
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
    }
}

.pagination-next-prev ul li a {
    padding: 9px 14px;
    border-bottom: none;
    display: inline-block;
    color: $white;
    background-color: lighten($grey, 22%);
    font-weight: 700;
    margin: 0;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

.current-page {
    background-color: darken($grey, 3%) !important;
}

.products {
    .pagination ul li a, .pagination-next-prev ul li a {
        background-color: lighten($grey, 29%);
    }
}

.pagination {
    .current, ul li a:hover {
        background: darken($grey, 3%);
        color: $white;
    }
}

.pagination-next-prev {
    ul li a:hover {
        background: darken($grey, 3%);
        color: $white;
    }
    a {
        &.next {
            float: right;
        }
        &.prev {
            float: left;
        }
    }
    ul li a {
        width: 36px;
        height: 36px;
        padding: 0;
    }
    .prev:before {
        font-family: "FontAwesome";
        font-size: 13px;
        position: relative;
        display: block;
        line-height: 19px;
    }
    .next:before {
        font-family: "FontAwesome";
        font-size: 13px;
        position: relative;
        display: block;
        line-height: 19px;
        content: "";
        margin: 9px 0 0 14px;
    }
    .prev:before {
        content: "";
        margin: 9px 0 0 12px;
    }
}

/* Custom Margins for Pages */

.products .pagination-next-prev a.prev {
    margin: 0 0 0 20px;
}

.pagination-next-prev.full-width a {
    &.prev {
        margin: 0 0 0 10px;
    }
    &.next {
        margin: 0 10px 0 0;
    }
}

.pagination-container {
    margin: 0 0 40px 0;
}

.products .pagination-container {
    margin: 50px 0 0 0;
}

.masonry.pagination-container {
    margin: 20px 0 40px 0;
}

/* 404 Error Page
------------------------------------- */

#not-found {
    margin: 90px 0 50px 0;
    h2 {
        text-align: center;
        font-size: 240px;
        line-height: 210px;
        font-weight: 600;
        letter-spacing: -5px;
    }
    p {
        text-align: center;
        font-size: 28px;
        line-height: 48px;
        color: lighten($grey, 6%);
        &:first-of-type {
            font-size: 34px;
        }
    }
    i {
        color: darken($grey, 3%);
        font-size: 210px;
        top: -12px;
        position: relative;
    }
}

/* ------------------------------------------------------------------- */
/* Shortcodes
---------------------------------------------------------------------- */

/* Tabs
------------------------------------- */

.tabs-nav {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    li {
        float: left;
        line-height: 38px;
        overflow: hidden;
        padding: 0;
        position: relative;
        a {
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }
        @include screen-sm-down{
            margin-bottom: 10px;
        }
    }
}

.tab-reviews {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.tabs-nav li a {
    border-right: 1px solid $white;
    border-bottom: 1px solid $white;
    color: $grey;
    font-weight: 600;
    display: block;
    letter-spacing: 0;
    outline: none;
    padding: 0 20px;
    text-decoration: none;
}

.tab-reviews {
    color: lighten($grey, 14%);
}

.tabs-nav li.active {
    .tab-reviews {
        color: $white;
    }
    a {
        color: $dark-grey2;
    }
}

.tab-content {
    padding: 15px 0;
}

.tabs-container {
    overflow: hidden;
    width: 100%;
}

/* Regular Table
------------------------------------- */

table.basic-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: none;
    &.shortcode th {
        text-transform: uppercase;
    }
    th {
        text-align: left;
        padding: 10px 15px;
        color: darken($grey, 13%);
        vertical-align: top;
        font-size: 14px;
        font-weight: 600;
    }
    td {
        padding: 10px 15px;
        border-top: none;
        border-right: none;
    }
}

.basic-table tr:nth-child(odd) {
    background-color: #ddf9ff;
}

.tab-content .basic-table {
    margin: 0 0 20px 0;
}

/* Dropcaps
------------------------------------- */

.dropcap {
    float: left;
    color: darken($grey, 3%);
    font-size: 58px;
    line-height: 54px;
    padding-top: 4px;
    padding-right: 10px;
    margin-top: -2px;
    font-weight: 600;
    &.full {
        background: darken($grey, 3%);
        padding: 3px 5px 5px 5px;
        margin: 0 5px 0 0;
        color: $white;
        bottom: -5px;
        position: relative;
    }
}

/* Highlights
------------------------------------- */

.highlight {
    &.color, &.gray, &.light {
        padding: 2px 6px;
        color: $white;
    }
    &.color {
        background: darken($grey, 3%);
    }
    &.gray {
        background: lighten($grey, 10%);
    }
    &.light {
        background: lighten($grey, 43%);
        color: lighten($grey, 3%);
    }
}

/* Blockquote
------------------------------------- */

blockquote {
    border-left: 4px solid darken($grey, 3%);
    padding-left: 20px;
    color: lighten($grey, 6%);
    line-height: 24px;
    font-style: italic;
    margin: 5px 0 20px 15px;
}

/* List Styles
------------------------------------- */

.list-1 li, .list-2 li, .list-3 li, .list-4 li {
    list-style: none;
    margin: 7px 0;
    margin-left: 2px;
}

.list-2 li {
    list-style: none;
    margin: 4px 0;
    margin-left: 2px;
}

.list-1 li:first-child, .list-2 li:first-child, .list-3 li:first-child, .list-4 li:first-child {
    margin-top: 0;
}

.list-1 li:before, .list-2 li:before, .list-3 li:before, .list-4 li:before {
    content: "";
    font-family: "FontAwesome";
    margin: 0 7px 0 0;
    position: relative;
    color: lighten($grey, 10%);
}

.list-1.color li:before, .list-2.color li:before, .list-3.color li:before, .list-4.color li:before {
    color: darken($grey, 3%);
}

.list-1 li:before {
    content: "";
}

.list-2 li:before {
    content: "•";
    font-size: 24px;
    top: 3px;
}

.list-3 li:before {
    content: "";
}

.list-4 li:before {
    content: "";
}

/* Tooltips
------------------------------------- */

#tiptip_holder {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999;
    font-family: 'Lato', 'Open Sans';
    &.tip_top {
        padding-bottom: 5px;
        -webkit-transform: translateX(12px);
    }
    &.tip_bottom {
        padding-top: 5px;
    }
    &.tip_right {
        padding-left: 5px;
    }
    &.tip_left {
        padding-right: 5px;
    }
}

#tiptip_content {
    color: $white;
    font-size: 13px;
    line-height: 16px;
    padding: 6px 10px;
    background-color: darken($grey, 13%);
}

#tiptip_arrow, #tiptip_arrow_inner {
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    height: 0;
    width: 0;
}

#tiptip_holder {
    &.tip_top #tiptip_arrow {
        border-top-color: darken($grey, 13%);
    }
    &.tip_bottom #tiptip_arrow {
        border-bottom-color: darken($grey, 13%);
    }
    &.tip_right #tiptip_arrow {
        border-right-color: darken($grey, 13%);
    }
    &.tip_left #tiptip_arrow {
        border-left-color: darken($grey, 13%);
    }
    &.tip_top #tiptip_arrow_inner {
        margin-top: -7px;
        margin-left: -6px;
        border-top-color: darken($grey, 13%);
    }
    &.tip_bottom #tiptip_arrow_inner {
        margin-top: -5px;
        margin-left: -6px;
        border-bottom-color: darken($grey, 13%);
    }
    &.tip_right #tiptip_arrow_inner {
        margin-top: -6px;
        margin-left: -5px;
        border-right-color: darken($grey, 13%);
    }
    &.tip_left #tiptip_arrow_inner {
        margin-top: -6px;
        margin-left: -7px;
        border-left-color: darken($grey, 13%);
    }
}

/* Google Maps
------------------------------------- */

#googlemaps {
    height: 40vh;
    display: block;
}

.google-map-container {
    margin: 0 0 35px 0;
    img {
        max-width: none !important;
    }
}

/* Contact Details
------------------------------------- */

.contact-informations p {
    margin: 0;
}

.contact-details-alt p {
    margin: 0 0 0 22px;
    line-height: 18px;
}

.contact-informations {
    li {
        i {
            float: left;
            margin: 3px 8px 0 0;
            color: lighten($grey, 3%);
        }
        padding-bottom: 3px;
    }
    &.second li {
        padding-bottom: 3px;
    }
}

/* Contact Form
------------------------------------- */

#contact {
    fieldset div {
        margin-bottom: 15px;
        input {
            width: 240px;
        }
    }
    textarea {
        width: auto;
        min-width: 97.5%;
        min-height: 180px;
        resize: vertical;
    }
    input, textarea, select {
        -webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
        -moz-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
        -o-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
        -ms-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
        transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
    }
    input:focus #contact textarea:focus, select:focus {
        outline: none;
        position: relative;
        z-index: 5;
    }
    input.error, textarea.error, select.error {
        border: 1px solid $contact-border;
        background: $contact-border;
    }
    label {
        margin-bottom: 5px;
        span {
            color: $contact-label;
        }
    }
}

#message {
    margin: 0;
    padding: 0;
    display: block;
    background: transparent none;
}

/* Example Grid
------------------------------------- */

.example-grid {
    .column, .columns {
        display: block;
        background: lighten($grey, 41%);
        padding: 10px 0;
        text-align: center;
        color: lighten($grey, 3%);
        font-weight: 600;
        margin-top: 20px;
    }
    .column:hover, .columns:hover {
        background: lighten($grey, 22%);
        color: $white;
        transition: all 0.2s ease-in-out;
    }
}

/* Icons Example */

.icons-example ul li {
    i {
        color: $dark-grey;
        width: 25px;
    }
    margin: 0 0 4px 4px;
}

/* Example Gallery */

.example-gallery {
    a {
        float: left;
    }
    strong {
        display: block;
    }
}

/* Comments
------------------------------------- */

.comments {
    margin: 10px 0 0 0;
    .button {
        margin: 0 0 10px 90px;
        padding: 7px 15px;
    }
    ul li {
        display: block;
        margin: 0 0 20px 90px;
        position: relative;
        ul li {
            margin: 20px 0 0 90px;
        }
    }
}

.comment-content {
    p {
        margin: 0;
        line-height: 23px;
    }
    background: lighten($grey, 44%);
    color: $grey;
    padding: 19px 22px 22px 22px;
    strong {
        padding-right: 5px;
        font-size: 14px;
        color: darken($grey, 13%);
    }
    span {
        color: lighten($grey, 6%);
    }
}

.comments-amount {
    color: lighten($grey, 3%);
}

.avatar {
    float: left;
    display: inline-block;
    position: absolute;
    left: -90px;
    img {
        max-width: 70px;
    }
}

.comment-by {
    width: 100%;
    padding-bottom: 8px;
    padding-top: 5px;
    span.reply {
        float: right;
        display: inline;
        a {
            float: right;
            height: 17px;
            margin-left: 5px;
            font-weight: normal;
            color: lighten($grey, 6%);
            i {
                color: lighten($grey, 20%);
                transition: all 0.1s ease-in-out;
            }
            &:hover {
                color: darken($grey, 3%);
                i {
                    color: darken($grey, 3%);
                }
            }
        }
    }
    .rating {
        float: right;
        position: relative;
        margin: 0 -10px 0 0;
        .star-bg:after {
            color: lighten($grey, 33%);
        }
    }
    span.date {
        color: lighten($grey, 6%);
        font-weight: 300;
        background: url('../images/comments-divider.png') no-repeat 0 55%;
        padding: 0 0 0 9px;
        margin: 0 0 0 2px;
        float: none;
        text-align: left;
    }
}

/* Add Comment */

.add-comment {
    label {
        margin: 0 0 4px 0;
        span {
            color: $contact-label;
        }
    }
    input {
        margin: 0 0 20px 0;
        min-width: 240px;
    }
    textarea {
        margin: 0 0 18px 0;
        width: auto;
        min-width: 97.5%;
        min-height: 180px;
        resize: vertical;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none;
    }
}

/* Content Boxes
------------------------------------- */

.content-box {
    padding: 32px 28px;
    margin: 0 0 20px 0;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    i, p, h3 {
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
    p {
        margin: 0;
        color: $white;
    }
    h3 {
        line-height: 26px;
        padding-bottom: 8px;
        color: $white;
        font-weight: 600;
    }
    i {
        font-size: 80px;
        height: 80px;
        color: $white;
        margin: 10px 0;
        display: block;
    }
    &.color-effect-1 {
        background: $light-pink;
    }
    &.color-effect-2 {
        background: $dropbox;
    }
    &.color-effect-3 {
        background: $purple;
    }
    &.color-effect-4 {
        background: $yellow;
    }
}

.box-icon-wrap {
    text-align: center;
    margin: 0 auto;
    padding: 15px 0 20px 0;
    max-height: 120px;
}

.box-icon i {
    display: inline-block;
    font-size: 0;
    cursor: pointer;
    margin: 15px 0;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    color: $white;
    &:after {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        content: '';
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }
    &:before {
        speak: none;
        font-size: 40px;
        line-height: 90px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        display: block;
        -webkit-font-smoothing: antialiased;
    }
}

/* Effect 1 */

.box-icon-effect-1 .box-icon i {
    background: rgba(255, 255, 255, 0.2);
    color: $white;
    -webkit-transition: background 0.2s, color 0.2s;
    -moz-transition: background 0.2s, color 0.2s;
    transition: background 0.2s, color 0.2s;
    &:after {
        top: -7px;
        left: -7px;
        padding: 7px;
        box-shadow: 0 0 0 4px $white;
        -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
        -webkit-transform: scale(0.8);
        -moz-transition: -moz-transform 0.2s, opacity 0.2s;
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transition: transform 0.2s, opacity 0.2s;
        transform: scale(0.8);
        opacity: 0;
    }
}

/* Effect 1a */

.content-box:hover .box-icon-effect-1a .box-icon i {
    background: rgba(255, 255, 255, 1);
    color: $light-pink;
    &:after {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

/* Effect 2 */

.box-icon-effect-2 .box-icon i {
    box-shadow: 0 0 0 4px $white;
    overflow: hidden;
    -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    color: $white;
    &:after {
        display: none;
    }
}

.content-box:hover {
    .box-icon-effect-2 .box-icon i {
        background: $white;
        color: $content-box-hover;
        box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.3);
    }
    .box-icon-effect-2a .box-icon i:before {
        -webkit-animation: toRightFromLeft 0.3s forwards;
        -moz-animation: toRightFromLeft 0.3s forwards;
        animation: toRightFromLeft 0.3s forwards;
    }
}

@-webkit-keyframes toRightFromLeft {
    49% {
        -webkit-transform: translate(100%);
    }

    50% {
        opacity: 0;
        -webkit-transform: translate(-100%);
    }

    51% {
        opacity: 1;
    }
}


@-moz-keyframes toRightFromLeft {
    49% {
        -moz-transform: translate(100%);
    }

    50% {
        opacity: 0;
        -moz-transform: translate(-100%);
    }

    51% {
        opacity: 1;
    }
}


@keyframes toRightFromLeft {
    49% {
        transform: translate(100%);
    }

    50% {
        opacity: 0;
        transform: translate(-100%);
    }

    51% {
        opacity: 1;
    }
}


/* Effect 3 */

.box-icon-effect-3 .box-icon i {
    box-shadow: 0 0 0 4px $white;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
    &:after {
        top: -2px;
        left: -2px;
        padding: 2px;
        z-index: -1;
        background: $white;
        -webkit-transition: -webkit-transform 0.2s, opacity 0.3s;
        -moz-transition: -moz-transform 0.2s, opacity 0.3s;
        transition: transform 0.2s, opacity 0.3s;
    }
}

/* Effect 3a */

.box-icon-effect-3a .box-icon i {
    color: $purple;
}

.content-box:hover .box-icon-effect-3a .box-icon i {
    color: $white;
    &:after {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
        opacity: 0;
    }
}

/* Effect 4 */

.box-icon-effect-4 .box-icon i {
    color: $white;
    box-shadow: 0 0 0 3px $white;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
    &:after {
        top: -2px;
        left: -2px;
        padding: 2px;
        z-index: -1;
        background: rgba(255, 255, 255, 0.2);
        -webkit-transition: -webkit-transform 0.2s, opacity 0.2s, background 0.2s;
        -moz-transition: -moz-transform 0.2s, opacity 0.2s, background 0.2s;
        transition: transform 0.2s, opacity 0.2s, background 0.2s;
    }
}

.content-box:hover {
    .box-icon-effect-4a .box-icon i:after {
        -webkit-transform: scale(0.85);
        -moz-transform: scale(0.85);
        -ms-transform: scale(0.85);
        transform: scale(0.85);
    }
    .box-icon-effect-4 .box-icon i:after {
        background: $white;
    }
    .box-icon-effect-4a .box-icon i {
        color: $yellow;
    }
}

/* Accordions & Toggles
------------------------------------- */

.icon-box span {
    i {
        font-size: 21px;
        margin: 20px 0;
        color: lighten($grey, 10%);
        z-index: 5;
        position: relative;
        text-align: center;
    }
    background-color: lighten($grey, 41%);
    text-align: center;
    float: left;
    display: block;
    width: 60px;
    height: 60px;
}

.icon-description {
    float: left;
    margin: -65px 0 0 82px;
    h3 {
        font-size: 20px;
        margin-bottom: 4px;
    }
    a {
        &:after {
            margin: 0 0 0 5px;
            position: relative;
            content: "";
            font-family: "FontAwesome";
        }
        &:hover {
            color: $grey;
        }
        top: -3px;
        position: relative;
        display: block;
    }
}

.icon-box {
    span {
        i {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    &:hover span {
        background-color: darken($grey, 3%);
        i {
            color: $white;
        }
    }
}

/* Accordions & Toggles
------------------------------------- */

.ui-accordion .ui-accordion-header, .trigger a {
    display: block;
    cursor: pointer;
    position: relative;
    line-height: 26px;
    outline: none;
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
    background-color: lighten($grey, 43%);
    -webkit-transition: background-color 200ms ease-in-out;
    -moz-transition: background-color 200ms ease-in-out;
    -o-transition: background-color 200ms ease-in-out;
    -ms-transition: background-color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out;
    padding: 10px 16px;
    margin-bottom: 10px;
    border: 2px solid transparent;
}

.ui-accordion .ui-accordion-header-active {
    &:hover {
        background: $white;
    }
    background-color: #e5f6f9;
    border: 2px solid $light-blue;
    color: $light-blue;
}

.trigger.active a {
    &:hover {
        background: $white;
    }
    background-color: #e5f6f9;
    border: 2px solid $light-blue;
    color: $light-blue;
}

.ui-accordion .ui-accordion-header i, .trigger a i {
    margin: 0 4px 0 0;
    position: relative;
}

.ui-accordion .ui-accordion-content, .toggle-container {
    padding: 0 16px 10px 16px;
}

.ui-accordion .ui-accordion-content p {
    margin: 0;
}

.ui-accordion-icon:before, .ui-accordion-icon-active:before, .toggle-icon:before {
    font-family: "Ionicons";
    content: "\f446";
    color: $dark-grey2;
    font-size: 14px;
    font-weight: 500;
    display: block;
    float: right;
    line-height: 25px;
    margin: 2px 4px 0 0;
}

.toggle-container p {
    margin: 0;
}

.ui-accordion-icon-active:before, .trigger.active .toggle-icon:before {
    content: "";
    color: $light-blue-hover;
}

.faq {
    .toggle-icon:before, .trigger.active .toggle-icon:before {
        content: "\f142";
        font-size: 24px;
    }
}

/* Share Buttons
------------------------------------- */

.share-buttons {
    float: left;
    height: auto;
    position: relative;
    z-index: 999;
    .social-p {
        display: none;
    }
    ul li {
        float: left;
        line-height: 24px;
        background: $white;
        overflow: hidden;
        display: inline;
    }
    i,
    a {
        cursor: pointer;
        color: $white;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        padding: 6px 12px;
        margin: 0 0 1px 0;
        line-height: 20px;
        float: left;
        height: 32px;
        width: auto;
    }
    ul li:first-child i,
    ul li:first-child a {
        background: darken($grey, 3%);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &.share-hovered {
            background-color: lighten($grey, 22%);
        }
    }
}

.share-facebook i,
.share-facebook a {
    background: $facebook;
    transition: 0.3s;
    &:hover, &:focus {
        background: darken($facebook, 10%);
    }
}

.share-instagram i,
.share-instagram a {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.share-twitter i,
.share-twitter a {
    background: $twitter;
    transition: 0.3s;
    &:hover, &:focus {
        background: darken($twitter, 10%);
    }
}

.share-gplus i,
.share-gplus a {
    background: $gplus;
    transition: 0.3s;
    &:hover, &:focus {
        background: darken($gplus, 10%);
    }
}

.share-pinit i,
.share-pinit a {
    background: $pinterest;
    transition: 0.3s;
    &:hover, &:focus {
        background: darken($pinterest, 10%);
    }
}

.og-details .share-buttons {
    margin: 10px 0;
}

.product-page section .share-buttons {
    margin: 10px 0 3px 0;
}

.post-content .share-buttons {
    margin: 25px 0 0 0;
}

/* Pricing Tables
------------------------------------- */

.plan-price {
    h3 {
        text-align: center;
        font-size: 20px;
        padding: 10px 0;
        margin-bottom: 25px;
        color: $dark-grey;
        font-weight: 600;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.02);
    }
    font-size: 36px;
    font-weight: 300;
    color: $dark-grey;
    text-align: center;
    padding: 0 0 22px 0;
    position: relative;
}

/* Colors */

.plan {
    &.color-1 {
        .plan-price, a.button {
            background-color: lighten($grey, 43%);
        }
    }
    &.color-2 {
        .plan-price, a.button {
            background-color: $light-pink;
        }
    }
    &.color-3 {
        .plan-price, a.button {
            background-color: $purple;
        }
    }
    &.color-4 {
        .plan-price, a.button {
            background-color: $pink;
        }
    }
    &.color-5 {
        .plan-price, a.button {
            background-color: $cherry;
        }
    }
}

.plan-price .value {
    font-weight: 600;
    letter-spacing: -1px;
}

.plan-currency {
    font-size: 22px;
    opacity: 0.7;
    position: relative;
    margin: 0 -5px 0 0;
    top: -4px;
    font-weight: 300;
}

.period {
    display: block;
    font-size: 16px;
    margin: 2px 0 0 0;
    opacity: 0.7;
}

.plan-features {
    background: $white;
    border-top: none;
    padding: 12px 0 0 0;
    ul li {
        padding: 8px 0;
        text-align: center;
    }
    a.button {
        position: relative;
        display: block;
        margin: 0 auto;
        margin: 12px 0;
        text-align: center;
        color: darken($grey, 13%);
        padding: 10px 0;
        font-size: 14px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
}

.color-2 .plan-price, .color-3 .plan-price, .color-4 .plan-price, .color-5 .plan-price, .color-2 .plan-price h3, .color-3 .plan-price h3, .color-4 .plan-price h3, .color-5 .plan-price h3, .color-2 .plan-price .plan-currency, .color-3 .plan-price .plan-currency, .color-4 .plan-price .plan-currency, .color-5 .plan-price .plan-currency, .color-2 .plan-features a.button, .color-3 .plan-features a.button, .color-4 .plan-features a.button, .color-5 .plan-features a.button {
    color: $white;
}

.color-2 .plan-price h3, .color-3 .plan-price h3, .color-4 .plan-price h3, .color-5 .plan-price h3 {
    background-color: rgba(0, 0, 0, 0.05);
}

/* ------------------------------------------------------------------- */
/* Revolution Slider
---------------------------------------------------------------------- */

.tp-banner-container {
    margin: 0 0 30px 0;
    max-height: 480px;
}

/* SMOOTHER ANIMATION ON ANDROID */

.tp-simpleresponsive {
    -webkit-perspective: 1500px;
    -moz-perspective: 1500px;
    -o-perspective: 1500px;
    -ms-perspective: 1500px;
    perspective: 1500px;
}

.fullscreen-container {
    width: 100%;
    position: relative;
    padding: 0;
}

.fullwidthbanner-container {
    width: 100%;
    position: relative;
    padding: 0;
    overflow: hidden;
    .fullwidthbanner {
        width: 100%;
        position: relative;
    }
}

.tp-bgimg.defaultimg {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

/* Captions
------------------------------------- */

.caption {
    z-index: 1;
    position: absolute;
    h2 {
        font-size: 52px;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
        padding: 0 0 15px 0;
        letter-spacing: -1px;
        display: block;
    }
    h3 {
        font-size: 32px;
        font-weight: 300;
        color: $white;
        text-transform: uppercase;
        padding: 0 0 20px 0;
    }
    p {
        font-size: 16px;
        font-weight: 500;
        color: $white;
        line-height: 25px;
    }
    &.dark {
        h2 {
            color: $black;
            padding: 0 0 15px 0;
            letter-spacing: -1px;
            font-size: 48px;
        }
        h3 {
            color: $black;
            padding: 0 0 15px 0;
            letter-spacing: -1px;
            font-size: 52px;
            font-size: 32px;
            padding: 0 0 20px 0;
            font-weight: 300;
        }
        p {
            color: lighten($grey, 6%);
        }
    }
    &.description {
        padding: 30px;
        margin: 50px;
        width: 25%;
        z-index: 1;
        background-color: darken($grey, 33%);
        background: rgba(0, 0, 0, 0.7);
        display: block;
        h3 {
            color: $white;
            font-size: 16px;
            padding-bottom: 10px;
            margin: 0;
            line-height: 22px;
            font-weight: 700;
            position: relative;
            display: block;
            letter-spacing: 0;
        }
        p {
            margin: 0;
            line-height: 22px;
            font-size: 14px;
            color: lighten($grey, 20%);
            display: none;
            display: block;
            position: relative;
            float: left;
        }
    }
}

/* Caption With Description */

.tp-simpleresponsive {
    .caption {
        position: absolute;
        visibility: hidden;
        -webkit-font-smoothing: antialiased !important;
    }
    img {
        max-width: none;
    }
}

a.caption-btn {
    cursor: pointer;
    padding: 15px 25px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    outline: none;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-color: $white;
    color: darken($grey, 20%);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: $white;
        background: darken($grey, 3%);
    }
}

/* Navigation Arrows
------------------------------------- */

.tparrows {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    -ms-transition: opacity 0.2s ease-out;
    -webkit-transform: translateZ(5000px);
    -webkit-transform-style: flat;
    -webkit-backface-visibility: hidden;
    z-index: 600;
    position: relative;
    &.hidearrows {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -moz-opacity: 0;
        -khtml-opacity: 0;
        opacity: 0;
        -webkit-transition-delay: 0.2s;
        -moz-transition-delay: 0.2s;
        -ms-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }
}

.tp-leftarrow, .tp-rightarrow {
    z-index: 100;
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 50px;
    color: darken($grey, 20%);
    background-color: $white;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-out;
    -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-out;
}

.tp-leftarrow:before, .tp-rightarrow:before {
    font-family: "FontAwesome";
    font-size: 18px;
    top: 13px;
    position: relative;
}

.tp-leftarrow:before {
    content: "";
    left: 17px;
}

.tp-rightarrow:before {
    content: "";
    left: 19px;
}

.tp-leftarrow:hover, .tp-rightarrow:hover {
    background-color: darken($grey, 3%);
    color: $white;
}

/* Basic Settings
------------------------------------- */

.tp-simpleresponsive {
    img {
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
    }
    a {
        text-decoration: none;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    > ul > li {
        list-stye: none;
        position: absolute;
        visibility: hidden;
    }
}

/*  CAPTION SLIDELINK   **/

.caption.slidelink a div, .tp-caption.slidelink a div {
    width: 3000px;
    height: 1500px;
}

/* Loader
------------------------------------- */

.tp-loader {
    top: 50%;
    left: 50%;
    z-index: 10000;
    position: absolute;
    &.spinner0 {
        width: 40px;
        height: 40px;
        background: url(../images/loader.gif) no-repeat center center;
        background-color: $white;
        margin-top: -20px;
        margin-left: -20px;
        -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
        animation: tp-rotateplane 1.2s infinite ease-in-out;
        border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
    }
}

@-webkit-keyframes tp-rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}


@keyframes tp-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}



/* Hover Image Captions
------------------------------------- */

a.img-caption {
    margin: 0 0 40px 0;
    display: block;
}

.img-caption {
    img {
        position: relative;
    }
    figure {
        box-sizing: content-box;
        overflow: hidden;
        position: relative;
    }
    figcaption {
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        box-sizing: content-box;
        padding: 17px 25px 17px 25px;
        bottom: 0;
        display: block;
        width: 100%;
        -webkit-transition: background-color 0.2s ease-in-out;
        -moz-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;
        -o-transition: background-color 0.2s ease-in-out;
        transition: background-color 0.2s ease-in-out;
    }
    &:hover figcaption {
        background: darken($grey, 3%);
        color:$dark-grey;
        h3{
            color:$dark-grey;
        }
    }
    figcaption h3 {
        color: $white;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
    }
}

.mega .img-caption figcaption {
    h3 {
        font-size: 14px;
        line-height: 20px;
    }
    span {
        font-size: 14px;
        line-height: 14px;
    }
    padding: 10px 20px 10px 20px;
}

.img-caption {
    figcaption span {
        font-size: 16px;
        font-weight: 300;
        line-height: 16px;
        display: block;
        position: relative;
        opacity: 0;
        max-height: 0;
        padding: 0;
        -webkit-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, padding 0.2s ease-in-out;
        -moz-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, padding 0.2s ease-in-out;
        -ms-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, padding 0.2s ease-in-out;
        -o-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, padding 0.2s ease-in-out;
        transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, padding 0.2s ease-in-out;
    }
    &:hover figcaption span {
        max-height: 50px;
        opacity: 1;
        padding: 5px 0 4px 0;
    }
}

/* Product Categories */

.product-categories .img-caption {
    figcaption {
        background: rgba(0, 0, 0, 0.3);
    }
    &:hover figcaption {
        background: darken($grey, 3%);
    }
    img {
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        opacity: 1;
        width: 100%;
    }
    &:hover img {
        opacity: 1;
    }
    figcaption {
        h3 {
            color: $white;
            text-transform: none;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
        }
        span {
            color: $white;
            text-transform: none;
            font-size: 16px;
            font-weight: 300;
            line-height: 14px;
        }
    }
}

/* ------------------------------------------------------------------- */
/* ShowBiz Carousel
---------------------------------------------------------------------- */

.showbiz-navigation {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.showbiz {
    margin: 0 0 45px 0;
    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    ul li {
        position: relative;
        -webkit-transform: translateZ(10);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        margin-right: 20px;
        margin-top: 20px;
    }
    .overflowholder {
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 2;
        ul {
            width: 100000px;
            position: absolute;
            top: 0;
            left: 0;
            li {
                float: left !important;
                position: relative;
            }
        }
    }
}

.products {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    & > div.columns {
        margin-top: 20px;
    }

    .product {
        position: relative;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 100%;
        align-items: stretch;
        -webkit-transition: box-shadow 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        -moz-transition: box-shadow 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        -o-transition: box-shadow 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: box-shadow 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        }
        & > a {
            -webkit-flex-grow: 1;
            flex-grow: 1;
            background-color: $white;
            display:flex;
            flex-direction: column;
        }
    }
    a {
        line-height: 18px;
    }
}

.products {
    .product {

        .clear-recommended-product{
            display: block !important;
            position: absolute !important;
            width: 30px;
            height: 30px;
            top: -15px;
            right: -15px;
            background: $virusmask-dark;
            color: $white;
            border-radius: 50%;
            z-index: 70;
            text-align: center;
            padding-top: 5px;
            &:hover{
                transition: background-color 0.3s;
                background: $red;
            }
            &:before{
                font-family: FontAwesome;
                display: inline-block;
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 18px;
                content: "\f014";
            }
        }
    }
}
.manufacturers{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    .manufacturer{
        position: relative;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 100%;
        align-items: stretch;
        background-color: #f8f8f8;

        .logo{
            width: 150px;
            height: 150px;
            margin:30px auto;
            img {
                border-radius: 50%;
            }
        }
        .details-cont{
            padding: 0px 20px 30px 20px;

            & > a {
                display:flex;
                flex-direction: column;
                text-align: center;
            }
            .name{
                color: #333333;
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: normal;
                line-height: 18px;
                text-align: left;
                /* Text style for "3M Company" */
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                margin-bottom: 10px;
                min-height: 50px;
            }
            .details{
                /* Style for "Székhely:" */
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                line-height: 18px;
                text-align: left;
                margin-bottom: 20px;
                &.details-upper {
                    min-height: 55px;
                }
                span{
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                }
            }
        }
        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        }


    }
    & > div.columns {
        margin-top: 20px;
    }
}
.manufacturer-section{
    padding:80px 0;
    .product-image-container {
        background: #F8F8F8;
        padding: 50px;
        display: flex;
        justify-content: center;
        img {
            border-radius: 50%;
        }
    }
}
.manufacturer-page{
    h1{
        color: #333333;
        font-size: 30px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 27px !important;
        text-align: left;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        padding-bottom:10px;
    }
    .details-container{
        padding-top:10px;
        border-top:2px solid #e6e6e6;
        span{
            font-weight: 700;
        }
        .details{
            margin-bottom: 30px;
        }
    }
}
.showbiz .mediaholder, .products .mediaholder {
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: transparent;
    padding: 0;
    border: none;
    overflow: hidden;
}

.showbiz .mediaholder_innerwrap, .products .mediaholder_innerwrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.showbiz .mediaholder img, .products .mediaholder img {
    width: 100%;
    position: relative;
    margin-bottom: -5px;
}

.showbiz .cover, .products .cover {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.showbiz li:hover .cover, .products figure:hover .cover {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.showbiz li .mediaholder img, .products figure .mediaholder img {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.showbiz li:hover .mediaholder img, .products figure:hover .mediaholder img {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.showbiz {
    .cover, li .mediaholder img {
        -webkit-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        -moz-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        -o-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        /* ease (default) */
        -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        -moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        -o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        /* ease (default) */
    }
}

.products {
    .cover, figure .mediaholder img {
        -webkit-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        -moz-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        -o-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
        /* ease (default) */
        -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        -moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        -o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        /* ease (default) */
    }
}

/* Navigation #1
------------------------------------- */

.showbiz-navigation {
    width: 53px;
    height: 25px;
    float: right;
    margin: -27px 0 0 -2px;
    background: $white;
    padding-left: 10px;
}

.sb-navigation-left, .sb-navigation-right, .sb-navigation-left.notclickable, .sb-navigation-right.notclickable {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.sb-navigation-left, .sb-navigation-right {
    width: 25px;
    height: 25px;
    cursor: pointer;
    float: left;
    background-color: lighten($grey, 23%);
}

.sb-navigation-left {
    margin-right: 3px;
    i {
        line-height: 24px;
        font-size: 17px;
        color: $white;
        margin-left: 10px;
    }
}

.sb-navigation-right i {
    line-height: 24px;
    font-size: 17px;
    color: $white;
    margin-left: 10px;
}

.sb-navigation-left:hover, .sb-navigation-right:hover {
    background-color: darken($grey, 3%);
}

.sb-navigation-left.notclickable, .sb-navigation-right.notclickable {
    cursor: default;
    background-color: lighten($grey, 41%) !important;
}

.sb-navigation-left.notclickable i, .sb-navigation-right.notclickable i {
    color: lighten($grey, 22%);
}

/* ShowBiz Product Styles
------------------------------------- */

.product section {
    padding: 18px 20px 0 20px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    &.product-category-wrapper {
        height: 150px;
        max-height: 150px;
        @media (max-width: 1389px) {
            height: 180px;
            max-height: 180px;
        }
        @media (max-width: 990px) {
            height: 235px;
            max-height: 235px;
        }
        @media (max-width: 767px) {
            height: 140px;
            max-height: 140px;
        }
        @media (max-width: 670px) {
            height: 160px;
            max-height: 160px;
        }
        @media (max-width: 530px) {
            height: 170px;
            max-height: 170px;
        }
        @media (max-width: 420px) {
            height: 210px;
            max-height: 210px;
        }
        @media (max-width: 380px) {
            height: 230px;
            max-height: 230px;
        }
        @media (max-width: 350px) {
            height: 245px;
            max-height: 245px;
        }
    }
    h5 {
        display: flex;
        color: $dark-grey2;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 9px;
        min-height: 36px;
        //@media (max-width: 990px) and (min-width: 768px) {
        //    height: 170px;
        //}
        //@media (max-width: 435px) {
        //    height: 175px;
        //}
    }
    &.extra-card {
        justify-content: center;
        align-items: center;
        .caret-icon {
            font-size: 50px;
            color: #cccccc;
            margin-bottom: 20px;
        }
        .extra-card-text {
            a {
                font-size: 20px;
                font-weight: 700;
                color: #333333;
            }
            text-align: center;
        }
        .extra-card-btn {
            margin-top: 35px;
            a {
                padding: 20px 60px;
            }
        }
    }
}

.product-button {
    width: 100%;
    top: auto;
    bottom: 0;
    background: rgba(59, 60, 65, 0.45);
    position: absolute;
    z-index: 10;
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.1s 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.1s 0.3s;
    transition: transform 0.3s, opacity 0.1s 0.3s;
}

a.product-button {
    color: $white;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    padding: 13px 0;
    i {
        margin-right: 4px;
        top: -1px;
        position: relative;
    }
}

.product:hover .product-button {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.1s, background 0.25s;
    -moz-transition: -moz-transform 0.3s, opacity 0.1s, background 0.25s;
    transition: transform 0.3s, opacity 0.1s, background 0.25s;
}

.product-button:hover {
    background: rgba(59, 60, 65, 0.65);
}

.product-category {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #999999;
    margin-bottom: 9px;
}

.product-price {
    font-size: 16px;
    font-weight: 700;
    color: $dark-grey;
    padding: 0 20px 10px 20px;

    span.coupon-discount-introductory,
    span.net {
        font-weight: 300;
        font-size: 14px;
    }

    .product-original-price-container{
        border-bottom: 2px solid #e6e6e6;
        padding-bottom: 9px;
        margin-bottom: 9px;
        span{
            text-decoration: line-through;
            color: #999999;
            font-size: 20px;
            line-height: 18px;
        }
    }
    .product-discount-prices-table{
        display: table;
        width: 100%;

        .prices-table-row {
            display: table-row;
        }

        .prices-table-cell {
            display: table-cell;
            padding: 2px;

            .discount-price-head{
                color: $dark-grey2;
                padding: 2px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
            }
            .discount-price{
                font-size: 20px;
                font-weight: 700;
                color: $dark-grey2;
                line-height: 20px;
            }
            .discount-price-description{
                font-size: 14px;
                font-weight: 400;
                color: lighten($dark-grey, 30%);
            }
        }

        @include screen-list-item-breakpoints{
            display: block;
            .prices-table-row,
            .prices-table-cell {
                display: block;
            }
        }
    }

}

.product-price-discount {
    font-size: 20px;
    font-weight: 700;
    display: block;
    padding: 10px 0 0;
    border-top: 2px solid #e6e6e6;
    .price-row {
        > div {
            display: inline-block;
            &.original-price{
                color:#acacac;
                font-size: 20px;
                font-weight: 700;
            }
            &.price-type {
                font-size: 14px;
            }
            &.gross-price {
                text-decoration: none;
                display: inline-block;
                font-weight: 700;
                font-size: 30px;
                .unit-price-description{
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            &.net-price{
                font-size: 14px;
            }
        }
        @include screen-sm-down{
            > div {
                padding: 10px 0;
                &.discount-percent-container{
                    padding: 10px 10px;
                }
            }
        }
    }
    .price-type {
        font-size: 14px;
    }
}

.product-discount {
    background-color: darken($grey, 3%);
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 9px;
    display: inline-block;
    position: absolute;
    color: $white;
    z-index: 99;
    top: 0;
    right: 0;
}

/* Products List
------------------------------------- */

ul.product-list {
    margin: 0;
    li {
        margin: 15px 0 0 0;
        display: block;
        float: left;
        width: 100%;
    }
}

.product-list img {
    display: inline;
    float: left;
    padding-right: 15px;
}

.product-list-desc {
    display: inline;
    font-size: 16px;
    color: darken($grey, 13%);
    top: 16px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    left: 0;
    position: relative;
    i {
        display: block;
        font-weight: 700;
        line-height: 24px;
        color: $dark-grey;
    }
}

.product-list {
    li a:hover .product-list-desc {
        left: 4px;
    }
    &.top-rated a span {
        margin: 9px 0 0 0;
    }
}

.product-list-desc.with-rating {
    top: 8px;
}

/* Star Rating */

.rating {
    box-sizing: content-box;
    overflow: hidden;
    position: relative;
    width: 100px;
    height: 18px;
    display: block;
    .star-rating {
        position: relative;
        width: inherit;
        display: block;
        padding: 0;
        margin: 0;
    }
    &.five-stars .star-rating:after {
        width: 100%;
    }
    &.four-stars .star-rating:after {
        width: 80%;
    }
    &.three-stars .star-rating:after {
        width: 60%;
    }
    &.two-stars .star-rating:after {
        width: 40%;
    }
    &.one-stars .star-rating:after {
        width: 20%;
    }
    .star-rating:after {
        content: "    ";
        font-family: "FontAwesome";
        color: $yellow;
        display: block;
        position: absolute;
        font-size: 16px;
        z-index: 10;
        letter-spacing: -0.5px;
    }
    .star-bg:after {
        content: "    ";
        font-family: "FontAwesome";
        color: lighten($grey, 35%);
        display: block;
        position: absolute;
        font-size: 16px;
        z-index: 9;
        padding: 0;
        margin: 0;
        letter-spacing: -0.5px;
    }
}

/* Rate */

.rate {
    unicode-bidi: bidi-override;
    direction: rtl;
    font-size: 18px;
    float: left;
    cursor: pointer;
    span.star {
        font-family: "FontAwesome";
        font-weight: normal;
        font-style: normal;
        display: inline-block;
        float: right;
        padding-right: 2px;
        &:hover {
            cursor: pointer;
        }
        &:before {
            content: "";
            color: lighten($grey, 35%);
        }
        &:hover {
            &:before, ~ span.star:before {
                content: "";
                color: $yellow;
            }
        }
    }
}

/* Discount */

.product-list.discount i {
    text-decoration: line-through;
    color: $grey;
    margin: 3px 0 0 0;
    b {
        color: darken($grey, 13%);
        font-weight: 700;
        text-decoration: none;
        display: inline-block;
        background-color: $contact-border;
        padding: 3px 5px;
        margin: 0 0 0 4px;
        line-height: 18px;
    }
}

/* Footer
---------------------------------------------------------------------- */

.main-container {
    @include screen-md-up {
        min-height: 500px;
    }
    @include screen-sm {
        min-height: 250px;
    }
}

#footer {
    background: $white;
    color: $dark-grey2;
    padding: 50px 0 0 20px;
    @include screen-sm-down{
        padding: 20px 0 0 0;
    }
    margin: 0;
    > .container{
        border-bottom: 1px solid #cccccc;
    }
    .footer-logo {
        height: 50px;
    }
    .footer-below-logo-text{
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
    }
    .footer-telephone-support{
        border: 1px solid #cccccc;
        padding: 20px 5px 20px 70px;
        position: relative;
        p{
            line-height: 18px;
            margin: 0;
            &.question{
                font-size: 18px;
                font-weight: 700;
                line-height: 17px;
                text-transform: uppercase;
            }
            &.answer{
                font-size: 14px;
            }
            &.tel{
                font-size: 20px;
                font-weight: 700;
            }
            &.opening-hours{

            }
            &.email{
                font-size: 16px;
                font-weight: 700;
            }
        }
        @include screen-sm-down {

        }
        &:before{
            font-family: 'Ionicons';
            content: '\f2d2';
            font-size: 40px;
            color: #fc461c;
            left: 20px;
            top: 40%;
            position: absolute;
        }

        @media (min-width: 991px) and (max-width: 1389px) {
            padding: 20px;
            &:before{
                content: "";
            }
        }
        @media (min-width: 768px) and (max-width: 990px) {
            padding: 0;
            border: 0;
            &:before{
                content: "";
            }
        }
    }
    h3.footer {
        color: $dark-grey2;
        background: transparent;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 10px;
        border-bottom: 0;

        &.headline{
            padding-bottom: 0;
            @include screen-xs{
                font-size: 20px;
            }
        }
    }
    .line {
        border-color: $white;
        margin-bottom: 28px;
    }
    a {
        color: $dark-grey2;
    }
    label {
        color: $virusmask-dark;
        padding-right: 14px;
    }
}

/* Bottom Footer
------------------------------------- */

#footer-bottom {
    background: $white;
    color: $dark-grey2;
    font-size: 14px;
    padding: 18px 0;
}

/* Newsletter
------------------------------------- */
.subscribe-newsletter {
    background-color: $dark-blue;
    padding: 30px 0;

    .subscribe-newsletter-text {
        color: $white;
        padding: 30px 0 30px 110px;
        text-transform: uppercase;
        font-size: 18px;
        background: url('/images/newsletter.svg') no-repeat left center;
        background-size: 100px;
        line-height: 16px;
        .subscribe-newsletter-text-secondary{
            font-size: 14px;
            text-transform: initial;
        }
    }
    .form-row.has-error .help-block {
        color:lighten(#d11c1c, 20%);
    }
    .subscribe-advert {
        color: #fefefe;
        font-size: 12px;
        line-height: normal;
        .checkbox label::after{
            top:4px;
        }
        .form-row{
            margin-bottom: 0 !important;
        }
    }
    .newsletter-form-container{
        display: flex;

        -webkit-justify-content: space-around;
        justify-content: space-around;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-align-items: stretch;
        align-items: stretch;

        .newsletter-btn {

            font-size: 13px;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            float: right;
            z-index: 5;
            position: relative;
            padding: 12px 15px;
            line-height: 18px;
            margin: 0 0 0 20px;
            height: 50px;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            background-color: $light-blue;
            border: none;
            color: $white;
            box-shadow: none;
            span {
                font-size: 24px;
                vertical-align: sub;
            }
            &:hover {
                background-color: $light-blue-hover;
            }

            @media (max-width: $screen-xs) {
                height: 50px;
            }
            @media (max-width: 387px) {
                margin: 0;
                margin-bottom: 10px;
            }
            flex-grow: 1;
        }
        &.lower{
            .newsletter-btn{
                margin:20px 0 0 0;
            }
        }
        > div.form-row{
            flex-grow: 4;
        }
        input {
            &.newsletter {
                color: $dark-grey2;
                font-weight: 400;
                position: relative;
                font-size: 14px;
                width: 100%;
                padding: 10px 14px;
                line-height: 18px;
                height: 50px;
                background-color: $white;
                border: none;
                -webkit-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                -ms-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
                &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
                    color: #999999;
                }
            }

            &:focus.newsletter {
                &::placeholder {
                    color: $dark-grey;
                }

                background-color: $white;
                border: none;
                color: $dark-grey2;
            }
        }
    }
}
/* Footer Links
------------------------------------- */

ul.footer-links {
    margin: 0 0 15px 0;
    vertical-align:top !important;

    .footer-contact {
        color: #fff !important;
    }
    .footer-contact-link {
        span {
            color: #fff !important;
        }
        display: inline-block !important;
        width: auto !important;
    }
}

.footer-links li {
    a {
        padding: 0 0 7px 0;
        display: inline-block;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        width:90%;
        line-height: 24px;
        @media (max-width: $screen-xs) {
            line-height: 32px;
            font-size: 20px;
            font-weight: 300;
        }
        &:hover {
            color: $light-blue !important;
        }
    }
}
#footer {
    .footer-contact-container {
        @media (max-width: $screen-xs) {
            line-height: 32px;
            font-size: 20px;
            font-weight: 300;
            a:hover {
                color: $light-blue !important;
            }
        }
    }
}
/* Payment Icons
------------------------------------- */
.shipping-icons,
.payment-icons {
    float: right;
    top: -2px;
    position: relative;
    li {
        a{
            display: block;
            width: 100%;
            height: 100%;
        }
        display: inline-block;
        float: left;
        height: 30px;
        width: 50px;
        border: 1px solid #cecece;
        padding:2px;
        margin: 5px 0 0 8px;
    }
}

/* ------------------------------------------------------------------- */
/* Widgets
---------------------------------------------------------------------- */

.widget {
    margin: 30px 0 0 0;
    p {
        margin: 0;
    }
    span.line {
        margin-bottom: 22px;
    }
}

/* Categories
------------------------------------- */

#categories {
    float: left;
    width: 100%;
    li {
        a {
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            transition: (all 0.2s ease-in-out,);
            span {
                -webkit-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                -ms-transition: all 0.2s ease-in-out;
                transition: (all 0.2s ease-in-out,);
            }
            display: block;
            padding: 11px 13px 10px 2px;
            text-decoration: none;
            color: darken($grey, 7%);
            border-bottom: 1px solid lighten($grey, 40%);
            margin-top: -1px;
            &:hover, &.active {
                background-color: darken($grey, 3%);
                color: $white;
                padding-left: 15px;
            }
        }
        li {
            ul {
                padding: 0;
            }
            a.active {
                background-color: transparent;
                padding-left: 25px;
                font-weight: 600;
                color: darken($grey, 3%);
            }
            ul {
                padding-left: 15px;
            }
            a.active span {
                color: darken($grey, 3%);
            }
        }
        a {
            span {
                color: lighten($grey, 14%);
            }
            &:hover span, &.active span {
                color: $white;
            }
        }
        ul {
            display: none;
            background-color: lighten($grey, 44%);
            padding: 15px 0;
            li a {
                padding: 3px 25px 4px 25px;
                border: none;
                color: darken($grey, 7%);
                background-color: lighten($grey, 44%);
                &:hover {
                    padding: 3px 25px 4px 25px;
                    border: none;
                    color: darken($grey, 7%);
                    background-color: lighten($grey, 44%);
                    span {
                        color: darken($grey, 3%);
                    }
                    color: darken($grey, 3%);
                }
            }
        }
    }
}

/* Filter By Price
------------------------------------- */

.padding-range {
    padding: 0 8px;
}

#price-range {
    display: block;
    position: relative;
    input {
        padding: 0;
        background: 0;
        border: 0;
        box-shadow: none;
        margin: 8px 0 0 4px;
        color: $grey;
        font-weight: 500;
        display: inline-block;
        width: 100px;
        float: left;
        font-size: 14px;
    }
    label {
        float: left;
        margin: 6px 0 0 0;
    }
    .button {
        float: right;
        padding: 6px 13px;
        margin: 0;
    }
}

#slider-range {
    margin: 0 0 16px 0;
}

#price-range {
    .ui-slider {
        position: relative;
        text-align: left;
        .ui-slider-handle {
            position: absolute;
            z-index: 2;
            width: 17px;
            height: 17px;
            margin: -3px 0 0 -8px;
            cursor: w-resize;
        }
        .ui-slider-range {
            position: absolute;
            z-index: 1;
            height: 11px;
            display: block;
            border: 0;
            background-position: 0;
        }
    }
    .ui-widget-content {
        background-color: lighten($grey, 30%);
        border-radius: 25px;
    }
    .ui-widget-header {
        background: lighten($grey, 41%);
    }
    .ui-state-default {
        background: darken($grey, 3%);
        border-radius: 50%;
    }
    .ui-slider.ui-state-disabled {
        .ui-slider-handle, .ui-slider-range {
            filter: inherit;
        }
    }
    .ui-slider-horizontal {
        height: 11px;
        .ui-slider-range {
            top: 0;
            height: 100%;
        }
        .ui-slider-range-min {
            left: 0;
        }
        .ui-slider-range-max {
            right: 0;
        }
    }
}

/* Ordering
------------------------------------- */

.selectricWrapper {
    position: relative;
    margin: 10px 0 0 20px;
    width: 205px;
    cursor: pointer;
}

.orderby {
    margin-left: 20px;
}

.full-width .orderby {
    margin-left: 0;
}

.selectricDisabled {
    filter: alpha(opacity = 50);
    opacity: 0.5;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectricOpen {
    z-index: 9999;
    .selectricItems {
        display: block;
    }
}

.selectricHideSelect {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
    select {
        position: absolute;
        left: -100%;
        display: none;
    }
}

.selectricInput {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    background: none !important;
}

.selectricTempShow {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
}

.selectric {
    border: 0 solid $white;
    background: lighten($grey, 22%);
    position: relative;
    .label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 40px 0 0;
        padding: 6px 15px;
        font-size: 13px;
        line-height: 28px;
        color: $white;
        min-height: 28px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .button {
        background: lighten($grey, 17%);
        border-radius: 0;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        color: $white;
        text-align: center;
        font: 0/0 a;
        &:after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: $white;
            border-bottom: none;
        }
    }
}

/* Options */

.selectricItems {
    display: none;
    position: absolute;
    overflow: auto;
    top: 100%;
    left: 0;
    background: lighten($grey, 44%);
    z-index: 9998;
    ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 20px;
        font-size: 13px;
    }
    ul {
        margin-top: 1px;
    }
    li {
        padding: 9px 15px;
        cursor: pointer;
        display: block;
        color: lighten($grey, 6%);
        border-bottom: 1px solid $light-grey;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-out;
        margin-top: -1px;
        &:last-child {
            border: 0;
        }
        &:hover {
            background: darken($grey, 3%);
            color: $white;
        }
    }
}

/* ------------------------------------------------------------------- */
/* Royal Slider
---------------------------------------------------------------------- */

#basic-slider, #product-slider, #product-slider-vertical {
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.product-slider {
    width: 100% !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
}

.slider-padding {
    padding-right: 20px;
    margin-bottom: 50px;
}

.royalSlider {
    width: 560px;
    height: 632px;
    position: relative;
    direction: ltr;
    > * {
        float: left;
    }
}

.rsWebkit3d .rsSlide {
    -webkit-transform: translateZ(0);
}

.royal-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    color: $white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 16px;
    text-align: center;
}

.rsWebkit3d {
    .rsSlide, .rsContainer, .rsThumbs, .rsPreloader, img, .rsOverflow, .rsBtnCenterer, .rsAbsoluteEl, .rsABlock, .rsLink {
        -webkit-backface-visibility: hidden;
    }
}

.rsFade.rsWebkit3d {
    .rsSlide, img, .rsContainer {
        -webkit-transform: none;
    }
}

.rsOverflow {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    float: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVisibleNearbyWrap {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    left: 0;
    top: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .rsOverflow {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.rsContainer {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsArrow, .rsThumbsArrow {
    cursor: pointer;
}

.rsThumb {
    float: left;
    position: relative;
}

.rsArrow, .rsNav, .rsThumbsArrow {
    opacity: 1;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.rsHidden {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
    -moz-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
    -o-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
    transition: visibility 0s linear 0.3s,opacity 0.3s linear;
}

.rsGCaption {
    width: 100%;
    float: left;
    text-align: center;
}

.royalSlider .rsSlide.rsFakePreloader {
    opacity: 1 !important;
    -webkit-transition: 0s;
    -moz-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    display: none;
}

.rsSlide {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.royalSlider.rsAutoHeight, .rsAutoHeight .rsSlide {
    height: auto;
}

.rsContent {
    width: 100%;
    height: 100%;
    position: relative;
}

.rsPreloader {
    position: absolute;
    z-index: 0;
}

.rsNav {
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
}

.rsNavItem {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25);
}

.rsThumbs {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    z-index: 22;
}

.rsTabs {
    float: left;
    background: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsThumbs {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVideoContainer {
    width: auto;
    height: auto;
    line-height: 0;
    position: relative;
}

.rsVideoFrameHolder {
    position: absolute;
    left: 0;
    top: 0;
    background: darken($grey, 46%);
    opacity: 0;
    -webkit-transition: .3s;
    &.rsVideoActive {
        opacity: 1;
    }
}

.rsVideoContainer {
    iframe, video, embed, .rsVideoObj {
        position: absolute;
        z-index: 50;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &.rsIOSVideo {
        iframe, video, embed {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding-right: 44px;
        }
    }
}

.rsABlock {
    left: 0;
    top: 0;
    position: absolute;
    z-index: 15;
}

img.rsImg {
    max-width: none;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grab-cursor {
    cursor: grab;
}

.grabbing-cursor {
    cursor: grabbing;
}

.rsNoDrag {
    cursor: auto;
}

.rsLink {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 20;
}

/* Directional Navigation (Next/Prev)
------------------------------------- */

.rsDefault {
    .rsArrow {
        height: 100%;
        width: 36px;
        position: absolute;
        display: block;
        cursor: pointer;
        z-index: 21;
    }
    &.rsHor {
        .rsArrowLeft {
            bottom: 0;
            right: 37px;
        }
        .rsArrowRight {
            bottom: 0;
            right: 0;
        }
    }
    .rsArrowIcn {
        width: 36px;
        height: 36px;
        position: absolute;
        cursor: pointer;
        color: $white;
        bottom: 0;
        font-family: "FontAwesome";
        font-size: 13px;
        background-color: lighten($grey, 22%);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        &:hover {
            background-color: darken($grey, 3%);
        }
    }
    &.rsHor {
        .rsArrowLeft .rsArrowIcn:before, .rsArrowRight .rsArrowIcn:before {
            position: relative;
            top: 6px;
            left: 14px;
        }
    }
}

.post-img .rsDefault.rsHor {
    .rsArrowLeft .rsArrowIcn:before, .rsArrowRight .rsArrowIcn:before {
        top: 7px;
        left: 14px;
    }
}

.rsDefault {
    &.rsHor {
        .rsArrowLeft .rsArrowIcn:before {
            content: "";
        }
        .rsArrowRight .rsArrowIcn:before {
            content: "";
        }
    }
    .rsThumbsHor {
        width: 100%;
        height: 105px;
    }
    .rsThumbsVer {
        width: 96px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
    &.rsWithThumbsHor .rsThumbsContainer {
        position: relative;
        height: 100%;
    }
    &.rsWithThumbsVer .rsThumbsContainer {
        position: relative;
        width: 100%;
    }
    .rsThumb {
        float: left;
        overflow: hidden;
        width: 96px;
        height: 105px;
        img {
            width: 100%;
            height: 100%;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
            opacity: 0.5;
            filter: alpha(opacity = 50);
        }
        &.rsNavSelected img, &:hover img {
            opacity: 1;
            filter: alpha(opacity = 100);
        }
    }
    .rsTmb {
        display: block;
    }
    .rsThumbsArrow {
        height: 100%;
        width: 30px;
        margin-top: 10px;
        position: absolute;
        display: block;
        cursor: pointer;
        z-index: 21;
        background: lighten($grey, 22%);
        color: $white;
        font-family: "FontAwesome";
        font-size: 13px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        opacity: 1;
        visibility: visible;
    }
}

/* Thumbnails
------------------------------------- */

/* Thumbnails Arrow Icons */

.rsThumbsVer .rsThumbArrow {
    top: 0 !important;
}

.rsDefault {
    .rsThumbsArrow:hover {
        background: darken($grey, 3%);
    }
    &.rsWithThumbsVer .rsThumbsArrow {
        width: 100%;
        height: 20px;
    }
    &.rsWithThumbsHor {
        .rsThumbsArrowLeft {
            left: 0;
            top: 0;
        }
        .rsThumbsArrowRight {
            right: 0;
            top: 0;
        }
        .rsThumbsArrowLeft .rsThumbsArrowIcn:before, .rsThumbsArrowRight .rsThumbsArrowIcn:before {
            top: 0;
            left: 0;
        }
        .rsThumbsArrowLeft .rsThumbsArrowIcn:before {
            content: "";
        }
        .rsThumbsArrowRight .rsThumbsArrowIcn:before {
            content: "";
        }
    }
    .rsThumbsArrowIcn {
        top: 47%;
        left: 50%;
        position: absolute;
        cursor: pointer;
        margin-top: -12px;
        margin-left: -4px;
    }
    .rsThumbsArrowDisabled {
        opacity: 0;
        visibility: hidden;
    }
    .rsPreloader {
        width: 20px;
        height: 20px;
        background-image: url('/images/preloader.gif');
        left: 50%;
        top: 50%;
        margin-left: -10px;
        margin-top: -10px;
    }
}

/* Preloader */

/* Product Page
------------------------------------- */

.product-page {
    position: relative;
    h2 {
        font-weight: 600;
    }
    .product-price {
        font-size: 24px;
        margin: 5px 0 0 0;
        font-weight: 500;
    }
    .product-price-discount {
        font-size: 24px;
        margin: 5px 0 0 0;
        font-weight: 500;
        i {
            font-weight: 600;
        }
    }
    .product-price {
        color: $grey;
        font-weight: 500;
    }
    .product-short-text{
        font-size: 16px;
        color:$dark-grey2;
    }
    .product-manufacturer {
        font-size: 16px;
        color: $dark-grey2;
        margin-bottom: 10px;
        p {
            margin-bottom: 0;
            a {
                &:after {
                    content: "";
                    display: none;
                }
            }
        }
    }
}

.reviews-counter {
    float: right;
    position: absolute;
    top: 2px;
    right: 0;
    font-size: 13px;
    text-transform: uppercase;
    padding: 0 10px 0 0;
    color: lighten($grey, 10%);
    text-align: right;
    .five-stars, .four-stars, .three-stars, .two-stars, .one-stars {
        text-align: left;
    }
    .rating {
        right: -14px;
    }
    span {
        position: relative;
        display: block;
        margin: -2px 0 0 0;
        color: lighten($grey, 10%);
    }
}
.product-image-container{
    .title {
        h1 {
            font-size: 20px;
            line-height: 30px;
        }
    }
}
.product-page {
    .title {
        padding: 0 0 20px 0;
        h1{
            font-size: 20px;
            line-height: normal;
            font-weight: 700;
            color: $dark-grey2;
        }
    }
    section {
        border-bottom: 2px solid #e6e6e6;
        padding: 10px 0;
        &.project-section {
            padding: 0 0 22px 0;
        }
        p a:after {
            content: "";
            font-family: "FontAwesome";
            margin: 0 1px 0 3px;
            font-size: 13px;
        }
        &.variant{
            padding-bottom:0 !important;
            border-bottom: 0 !important;
        }
        &.secure-payment{
            background: url('/images/secure-payment.svg') no-repeat left center;
            background-size:  44px 30px;
            padding-left: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
            ul.payment-icons{
                margin: 0 !important;
            }
        }
        &.shipping-modes {
            background: url('/images/shipping-modes.svg') no-repeat left center;
            background-size:  44px 44px;
            padding-left: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
            ul.shipping-icons{
                margin: 0 !important;
            }
        }
    }
}

/* Variables
------------------------------------- */

.variables {
    select {
        display: none;
        width: 100%;
        padding: 9px;
    }
    label {
        font-weight: 600;
        color: $dark-grey;
    }
}

section.variables {
    padding-bottom: 28px;
}

.customSelect {
    position: relative;
    float: left;
    width: 100%;
    background-color: lighten($grey, 43%);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .selectedValue {
        padding: 8px 15px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 13px;
        color: lighten($grey, 3%);
        cursor: pointer;
    }
    &.select-open {
        .selectedValue {
            background-color: lighten($grey, 22%);
            color: $white;
        }
        .caret {
            border-top-color: $white;
        }
    }
    .selectList {
        z-index: 100;
        position: absolute;
        left: -999em;
        top: -999em;
        font-size: 13px;
        list-style: none;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        width: 100%;
    }
    dd, .caret, &.select-open .caret, .selectedValue {
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
    &.select-open {
        .selectedValue {
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }
        .selectList {
            left: 0 !important;
            top: auto !important;
        }
    }
    .selectList dl {
        background: lighten($grey, 44%);
        border-bottom: 1px solid $light-grey;
    }
    dl {
        margin: 0;
    }
    dd {
        margin: 0;
        zoom: 1;
        cursor: pointer;
        padding: 6px 15px;
    }
    .disabled {
        color: lighten($grey, 6%);
        cursor: default;
    }
    .selectList dd.hovered {
        background: darken($grey, 3%);
        color: $white;
    }
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: top;
        border-top: 4px solid lighten($grey, 14%);
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
    }
    .selectValueWrap .caret {
        position: absolute;
        top: 18px;
        right: 18px;
        cursor: pointer;
    }
}

/* Quantity
------------------------------------- */

section.linking {
    padding-top: 28px;
}

#quantity {
    position: relative;
    float: left;
}

.qty {
    width: 50px;
    height: 50px;
    text-align: center;
    float: left;
    padding: 8px 9px;
}
input.qty,
input.qty:focus{
    background-color: $white;
    padding: 8px 9px;
    border-top: 1px solid #cecece;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #cecece;
}

.qtyplus, .qtyminus {
    background: $white;
    color: #999999;
    float: left;
    font-family: "Ionicons";
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size: 16px;
    outline: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    transition: background-color  0.2s ease-in-out;
    border: 1px solid #cecece;
    padding: 1px;
}
.qtyminus {
    border-right: 0;
    padding-right: 2px;
}
.qtyplus {
    border-left: 0;
    padding-left: 2px;
}


i.cart-remove,
a.cart-remove {
    border: none;
    float: left;
    font-family: "Ionicons";
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size: 16px;
    outline: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: $backgroundColor;
    color: $dark-grey2;
}

.qtyplus:hover, .qtyminus:hover {
    border: 2px solid $light-blue;
    color: $light-blue;
    background-color: #e5f6f9;
    padding: 0;
}

i.cart-remove:hover,
a.cart-remove:hover {
    background: $red;
    color: $white;
}

.qtyplus:before {
    content: "\f218";
}

.qtyminus:before {
    content: "\f209";
}

.cart-remove:before {
    content: "\f4c5";
}

.qtyplus:before, .qtyminus:before {
    position: relative;
    display: block;
    top: 12px;
    left: 18px;
}

.cart-remove:before {
    position: relative;
    top: 12px;
    left: 18px;
    font-size: 24px;
}

/* Add to Cart Button */

.linking a.button {
    margin: 0 0 0 25px;
    padding: 13px 30px 13px 30px;
    float: left;
    background-color: $light-blue;
    &.out-of-stock-product-button {
        margin-left: 0 !important;
    }
    span{
        font-size: 13px;
    }
    &.adc:before {
        content: "\f3f8";
        position: absolute;
        font-family: "Ionicons";
        padding: 13px 15px 13px 12px;
        height: 100%;
        top: 0;
        left: 0;
    }
    &.hovered,&:hover {
        background-color: $light-blue-hover;
        color:$white;
    }
}

/* Shopping Cart
------------------------------------- */

// Normál táblázat
table .cart-title,
// Stacktable táblázat
.cart .st-val {
    a {
        font-weight: 600;
        color: darken($grey, 13%);
    }

    .cart-subproducts a {
        font-weight: 400;
    }
}

table {
    &.cart-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border: none;

        th {
            border: none;
            background-color: $backgroundColor;
            text-align: left;
            padding: 10px 20px;
            color: $dark-grey;
            vertical-align: top;
            font-size: 14px;
            font-weight: 600;
            &.quantity-column{
                min-width: 190px;
            }
        }
        td {
            padding: 20px 20px;
            border-bottom: lighten($grey, 40%) 1px solid;
            border-top: none;
            border-right: none;
            vertical-align: middle;
            form.cart-item-controls {
                display:block;
                min-width:125px;
            }
        }
    }
    &.cart-table.padding-half{
        td{
            padding: 10px;
        }
    }
    .cart-total {
        color: darken($grey, 13%);
        font-weight: 600;
    }
    &.cart-table.bottom {
        margin-top: -1px;
        .cart-btns {
            float: right;
            a {
                margin-left: 10px;
                &:hover{
                    color:$white;
                }
            }
        }
        th {
            padding: 15px;
        }
    }
}



/* Cart Totals */

.eight.columns.cart-totals {
    float: right;
    margin: 45px 0 0 0;
    @include screen-xs{
        float:none;
        width:100%;
        display:block;
    }
}
.payment-footer-buttons{
    @include screen-xs{
        .columns{
            width: 100% !important;
        }
    }
}
.cart-totals .cart-table {
    th {
        vertical-align: middle;
        border-bottom: 1px solid $white;
        width: 40%;
        padding: 10px 15px;
    }
    td {
        border-bottom: 1px solid lighten($grey, 41%);
        padding: 10px 15px;
    }
    tr:last-child {
        th, td {
            border: 0;
        }
    }
}

table.cart-table.subtotals td {
    padding: 15px;
    border: none;
    vertical-align: middle;
}

a {
    &.button.proceed:hover {
        background-color: $dark-grey;
    }
    &.calculate-shipping {
        text-align: center;
        display: block;
        background: lighten($grey, 22%);
        color: $white;
        padding: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        i {
            font-size: 14px;
            padding: 0 2px;
        }
        &:hover {
            background: darken($grey, 3%);
            color: $white;
        }
    }
}

/* Responsive Tables */

.st-key {
    border: none;
    background-color: lighten($grey, 43%);
    text-align: left;
    padding: 10px 20px;
    color: $dark-grey;
    vertical-align: top;
    font-size: 14px;
    font-weight: 600;
    width: 40%;
    border: 1px solid lighten($grey, 40%);
}

.st-val {
    width: 58%;
    text-align: left;
    padding: 10px 20px;
    border: 1px solid lighten($grey, 41%);
    border-left: 0;
}

.st-space {
    height: 25px;
}

.st-val .cart-remove {
    color: lighten($grey, 10%);
    &:hover {
        color: $white;
    }
}

.stacktable {
    width: 100%;
}

.st-head-row {
    img {}
    &.st-head-row-main {
        display: block;
    }
}

.stacktable {
    &.large-only {
        display: table;
    }
    &.small-only {
        display: none;
    }
}

/* ------------------------------------------------------------------- */
/* Portfolio
---------------------------------------------------------------------- */

/* Dynamic Grid
------------------------------------- */

.og-grid {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    text-align: left;
    width: 100%;
    margin-left: 2px;
    > li.unclickable a {
        cursor: default;
    }
    li a img {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    > li {
        display: inline-block;
        margin: 0 8px 20px 8px;
        vertical-align: top;
        height: auto;
        float: none;
    }
}

/* Hover Effects */

.dynamic-portfolio-holder {
    overflow: hidden;
    position: relative;
    .hover-icon:before {
        top: 15px;
    }
}

.overflowholder .hover-icon:before {
    top: 15px;
}

.og-grid {
    li a:hover {
        .hover-cover {
            opacity: 0.5;
            visibility: visible;
        }
        .hover-icon {
            opacity: 1;
            visibility: visible;
            bottom: 0;
        }
        .hover-desc {
            opacity: 1;
            visibility: visible;
            top: 40%;
        }
    }
    > li {
        &.unclickable {
            .hover-desc, .hover-icon, .hover-cover {
                display: none;
            }
            opacity: 0.4;
        }
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
    }
}

/* Filtered Element Style */

.hover-desc {
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    text-align: center;
    width: 100%;
    top: 45%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    h5 {
        color: $white;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: -2px;
    }
    span {
        color: $white;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        opacity: 0.7;
    }
}

.og-expander {
    position: absolute;
    background: lighten($grey, 43%);
    top: auto;
    left: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: left;
    opacity: 0;
    overflow: hidden;
}

.og-expander-inner {
    padding: 0;
    height: 100%;
}

/* Close Button */

.og-close {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 9999;
    background: lighten($grey, 22%);
    color: $white;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:before {
        content: "";
        font-family: "FontAwesome";
        font-size: 16px;
        top: 15px;
        left: 18px;
        position: relative;
    }
    &:hover {
        background: darken($grey, 3%);
    }
}

/* Details */

.og-details {
    width: auto;
    overflow: hidden;
    position: relative;
    padding: 0 50px;
    margin: 45px 0;
    h3 {
        font-weight: 600;
        font-size: 24px;
        padding: 0;
        margin-bottom: 14px;
        margin-top: 0;
        color: $dark-grey;
    }
    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: lighten($grey, 3%);
        margin-bottom: 12px;
    }
}

/* Image Gallery */

.gridslider {
    float: left;
    display: inline-block;
    position: relative;
    width: 500px;
    overflow: hidden;
    img {
        height: 100%;
        max-width: inherit;
        width: auto;
    }
    width: 500px;
}

/* Columns Grid */

.og-expander {
    width: 1180px;
}

.og-grid > li {
    width: 280px;
}

@media only screen and (min-width: 960px) and (max-width: 1289px) {
    .gridslider {
        width: 400px;
    }
    .og-expander {
        width: 940px;
    }
    .og-grid > li {
        width: 220px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .gridslider {
        width: 350px;
    }
    .og-expander {
        width: 748px;
    }
    .og-grid > li {
        width: 236px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .gridslider, .og-expander, .og-grid > li {
        width: 420px;
    }
    .og-expander {
        left: 0;
    }
}

@media only screen and (max-width: 479px) {
    .gridslider, .og-expander, .og-grid > li {
        width: 300px;
    }
    .og-expander {
        left: 0;
    }
}

/* Portfolio Item
------------------------------------- */

.item-description {
    padding: 20px 20px 30px 20px;
    text-align: center;
    background-color: lighten($grey, 43%);
    h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        color: darken($grey, 9%);
        margin: 0;
    }
    span {
        color: lighten($grey, 14%);
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        margin: 0;
        text-transform: uppercase;
    }
}

.portfolio-holder {
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    img {
        width: 100%;
        position: relative;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
    }
}

/* Flexslider
------------------------------------- */

.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus {
    outline: none;
}

.slides, .flex-control-nav, .flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.flexslider {
    margin: 0;
    padding: 0;
    .slides {
        > li {
            display: none;
            -webkit-backface-visibility: hidden;
        }
        img {
            width: 100%;
            display: block;
        }
    }
}

/* Hide the slides before the JS is loaded. Avoids image jumping */

.flex-pauseplay span {
    text-transform: capitalize;
}

.slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

.no-js .slides > li:first-child {
    display: block;
}

/* FlexSlider Default Theme */

.flexslider {
    margin: 0 0 0;
    position: relative;
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
}

.carousel li {
    margin-right: 5px;
}

/* Direction Nav */

.flex-direction-nav {
    *height: 0;
    a {
        text-decoration: none;
        display: block;
        width: 36px;
        height: 36px;
        margin: 0;
        position: absolute;
        z-index: 10;
        overflow: hidden;
        opacity: 1;
        cursor: pointer;
        font-family: "FontAwesome";
        font-size: 13px;
        background-color: lighten($grey, 22%);
        color: $white;
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
    }
    .flex-prev {
        bottom: 0;
        right: 37px;
    }
    .flex-next {
        bottom: 0;
        right: 0;
    }
    .flex-prev:before, .flex-next:before {
        position: relative;
        top: 9px;
        left: 14px;
    }
    .flex-prev:before {
        content: "";
    }
    .flex-next:before {
        content: "";
    }
}

.flexslider {
    .flex-prev:hover, .flex-next:hover {
        background-color: darken($grey, 3%);
    }
    &:hover {
        .flex-next:hover, .flex-prev:hover {
            opacity: 1;
        }
    }
}

.flex-direction-nav .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity = 0);
    cursor: default;
}

/* Hover / Animations
------------------------------------- */

.hover-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0;
    visiblity: hidden;
}

.portfolio-item:hover {
    .hover-cover {
        opacity: 0.5;
        visibility: visible;
    }
    .hover-icon {
        opacity: 1;
        visibility: visible;
        bottom: 0;
    }
}

.hover-icon {
    position: absolute;
    right: 0;
    bottom: -48px;
    color: $white;
    font-size: 16px;
    width: 48px;
    height: 48px;
    background-color: darken($grey, 3%);
    border-radius: 0;
    opacity: 0;
    visibility: hidden;
    &:before {
        height: 19px;
        width: 19px;
        top: 12px;
        left: 18px;
        content: "";
        font-family: "FontAwesome";
        position: relative;
    }
    &.loading:before {
        content: "";
        height: 16px;
        width: 16px;
        top: 16px;
        left: 16px;
        background: url(../images/loading.png) no-repeat;
        display: block;
        -webkit-animation: spin 2s infinite linear;
        -ms-animation: spin 2s infinite linear;
        -o-animation: spin 2s infinite linear;
    }
}

.hover-cover, .hover-icon {
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    font-size: 14px;
}

/* Filters
------------------------------------- */

#portfolio-wrapper .portfolio-item {
    margin-bottom: 20px;
}

#filters {
    margin: 0 0 20px 0;
    float: left;
    li {
        float: left;
        display: inline-block;
        line-height: 24px;
        background: $white;
        overflow: hidden;
    }
    ul li {
        display: inline;
    }
    a {
        color: lighten($grey, 6%);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        padding: 6px 12px;
        margin: 0 1px 0 0;
        background-color: lighten($grey, 43%);
        line-height: 20px;
        -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
        -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
        -o-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
        -ms-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
        float: left;
        &:hover, &.selected {
            background-color: darken($grey, 3%);
            color: $white;
        }
    }
}

/* ------------------------------------------------------------------- */
/* Blog Styles
---------------------------------------------------------------------- */

/* Masonry
------------------------------------- */

.masonry-item .from-the-blog {
    margin: 0 0 20px 0;
}

/* Blog Quote */

.post-quote {
    background: $black;
    padding: 25px 25px 10px 25px;
    blockquote {
        color: $white;
        font-size: 18px;
        font-style: normal;
        line-height: 26px;
        border-left: 1px solid darken($grey, 13%);
        padding: 0 0 0 18px;
        margin-left: 45px;
        font-weight: 300;
    }
    span {
        text-decoration: none;
        display: block;
        margin: 10px 0 0 0;
        font-size: 14px;
        color: lighten($grey, 29%);
    }
    .icon:before {
        font-family: "FontAwesome";
        font-size: 24px;
        content: "";
        color: $white;
        position: relative;
        height: 15px;
        width: 25px;
        display: block;
        float: left;
        margin: 4px 0 0 0;
    }
}

/* Blog List
------------------------------------- */

.the-team-content, .from-the-blog-content {
    background-color: lighten($grey, 44%);
    padding: 20px;
    margin: 0;
}

.the-team-content h5, .from-the-blog-content a h5 {
    font-weight: 700;
    line-height: 22px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin: 0 0 3px 0;
}

.the-team h5 {
    font-size: 18px;
    margin: 0;
}

.the-team-content a:hover h5, .from-the-blog-content a:hover h5 {
    color: $grey;
}

.the-team-content i, .from-the-blog-content i {
    font-weight: 500;
    color: lighten($grey, 14%);
    font-size: 13px;
}

.the-team-content span, .from-the-blog-content span {
    font-size: 14px;
    display: block;
    margin: 9px 0 14px 0;
}

.the-team span {
    margin: 9px 0 17px 0;
}

.the-team-image, .from-the-blog-image {
    overflow: hidden;
    position: relative;
    img {
        width: 100%;
    }
}

.the-team-image a img {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.from-the-blog-image {
    a img {
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    &:hover .hover-icon {
        visibility: visible;
        bottom: 0;
        opacity: 1;
    }
}

/*.the-team-image a img,
.from-the-blog-image:hover a img {
        opacity: 0.9;
}*/

.from-the-blog-content.no-img {
    padding-top: 30px;
}

/* Posts
------------------------------------- */

.post {
    padding: 0 0 30px 0;
    margin: 50px 0 30px 0;
    &.main-lead {
        .post-content {
            margin: 65px 0 0 10px;
            @media (max-width: 1389px) and (min-width: 991px) {
                margin: 45px 0 0 10px;
            }
            @media (max-width: 990px) {
                margin: 25px 0 0 0;
                border-bottom: 1px solid #ebebeb;
                padding-bottom: 40px;
            }
            @media (max-width: 480px) {
                .meta {
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }
    &.single {
        border: 0;
        margin: 30px 0 10px 0;
        .post-img {
            margin: 0 0 23px 0;
        }
        .post-content {
            margin: 0 0 0 70px;
        }
        .meta {
            color: lighten($grey, 3%);
            margin: 0 0 15px 0;

            a {
                color: lighten($grey, 3%);
                text-decoration: none;
            }

            time {
                margin: 0 0 0 -2px;
            }

            a:hover {
                color: darken($grey, 3%);
            }

            i {
                display: inline-block;
                margin: 2px 5px 0 0;
                color: lighten($grey, 22%);
            }

            &:after {
                content: ".";
                display: block;
                clear: both;
                visibility: hidden;
                line-height: 0;
                height: 0;
            }

            span {
                margin-right: 12px;
                float: left;
            }

            h2 {
                line-height: 26px;
                font-size: 24px;
                font-weight: 600;
                margin: 0 0 2px 0;

                a {
                    color: $dark-grey;

                    &:hover {
                        color: $grey;
                    }
                }
            }
        }
    }
    #basic-slider {
        margin-bottom: -23px;
    }
}

.extra-padding {
    padding-right: 20px;
    &.left {
        padding-left: 20px;
    }
}



.post-img {
    overflow: hidden;
    position: relative;
    a {
        &:hover .hover-icon {
            opacity: 1;
            visibility: visible;
            bottom: 0;
        }
        img {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }
}

/*.post-img a:hover img {
        opacity: 0.9;
}*/

/* Tags */

.meta {
    color: lighten($virusmask-blog-text, 3%);
    margin: 0 0 15px 0;
    a {
        color: $virusmask-blog-text;
        text-decoration: none;
    }
    time {
        margin: 0 0 0 -2px;
    }
    a:hover {
        color: darken($virusmask-blog-text, 3%);
    }
    i {
        display: inline-block;
        margin: 2px 5px 0 0;
        color: $virusmask-blog-text;
    }
    &:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
    span {
        margin-right: 12px;
        float: left;
    }
    h2 {
        line-height: 38px;
        font-size: 32px;
        font-weight: 700;
        margin: 0 0 15px 0;
        a {
            color: $dark-grey;
            &:hover {
                color: lighten($dark-grey2, 15%) !important;
            }
        }
    }
}

/* Title */

/* Date */

.date {
    float: left;
    text-align: center;
    height: 0;
    span {
        display: block;
    }
}

.day {
    background: lighten($grey, 43%);
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    color: lighten($grey, 10%);
}

.month {
    background: lighten($grey, 22%);
    padding: 0 10px 2px 10px;
    color: $white;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
}

/* Tags
------------------------------------- */

.tags {
    margin: 5px 0 0 0;
    a {
        display: inline-block;
        padding:8px 15px 8px 12px;
        font-size:13px;
        border:1px solid #cecece;
        margin-bottom:5px;
        margin-right: 10px;
        -webkit-transition: background-color 0.2s ease-in-out;
        -moz-transition: background-color 0.2s ease-in-out;
        -o-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;
        transition: background-color  0.2s ease-in-out;
        color: $dark-grey2;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background-color: #e5f6f9;
            border: 2px solid $light-blue;
            padding:7px 14px 7px 11px;
            color: $light-blue;
        }
    }
}

/* Widget Tabs
------------------------------------- */

.widget-tabs li {
    padding: 0 0 15px 0;
    margin: 15px 0 0 0;
    border-bottom: 1px solid lighten($grey, 40%);
    &:first-child {
        margin: 4px 0 0 0;
    }
    &:last-child {
        border-bottom: 0;
    }
}

.widget-thumb a img {
    display: block;
    width: 74px;
    height: 74px;
    float: left;
    margin: 0 15px 0 0;
}

.widget-text {
    h4 {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        padding: 3px 0 0 0;
        a {
            color: darken($grey, 13%);
            &:hover {
                color: $grey;
            }
        }
    }
    span {
        color: lighten($grey, 10%);
        font-weight: 500;
        margin: 4px 0 0 0;
        display: block;
    }
}

.widget-tabs.comments .widget-text span {
    padding: 5px 0 0 0;
    margin: 0;
}

/* Blog Tabs */

.tabs-nav.blog li {
    width: 33.334%;
    text-align: center;
    a {
        padding: 0;
    }
}

/* Widget
------------------------------------- */

.categories li {
    a {
        padding: 0 0 7px 0;
        display: inline-block;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        color: $grey;
        &:hover {
            padding-left: 3px;
            color: darken($grey, 3%) !important;
        }
    }
    &:before {
        font-family: "FontAwesome";
        font-size: 12px;
        content: "";
        color: lighten($grey, 33%);
        position: relative;
        left: 0;
        top: -1px;
        padding: 0 7px 0 0;
    }
}

/* Article
----------------------------------------------------------------- */

.article-content {
    ul {
        margin-left: 20px;
        list-style-type: initial;
        margin-bottom: 14px;

    }
    li {
        margin-left: 1em;
    }
    p {
        &:first-of-type {
            margin-top: 15px;
        }
    }
    a strong {
        color: inherit;
    }
}

/* ------------------------------------------------------------------- */
/* Media Queries
---------------------------------------------------------------------- */

/* Standard 1180 (devices and browsers) */
@media only screen and (min-width: 1200px) {
    a.menu-trigger {
        display: none;
    }
}

/* Smaller than standard 1180 (devices and browsers) */
@media only screen and (min-width: 960px) and (max-width: 1389px) {
    #not-found {
        margin: 50px 0 75px 0;
        h2 {
            font-size: 210px;
            line-height: 210px;
        }
        p {
            font-size: 28px;
            line-height: 36px;
        }
        i {
            font-size: 200px;
            top: -5px;
        }
    }
    a.menu-trigger {
        display: none;
    }
    .product-list span {
        font-size: 14px;
    }
    .checkout-content {
        .half {
            width: 44.5%;
            &.first {
                padding: 0 30px 0 0;
            }
        }
        .fullwidth {
            width: 100%;
            select {
                width: 100%;
            }
        }
        select.fullwidth {
            width: 100%;
        }
    }
    table.checkout.cart-table td {
        padding: 15px;
    }
    .checkout-content {
        .half {
            width: 44.5%;
            &.first {
                padding: 0 30px 0 0;
            }
        }
        .fullwidth {
            width: 100%;
            select {
                width: 100%;
            }
        }
        select.fullwidth {
            width: 100%;
        }
    }
}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 990px) {
    .slider-padding {
        padding-right: 10px;
    }
    .extra-padding {
        padding-right: 10px;
        &.left {
            padding-left: 10px;
        }
    }
    #not-found {
        margin: 35px 0 60px 0;
        h2 {
            font-size: 160px;
            line-height: 160px;
        }
        i {
            font-size: 155px;
            top: -4px;
        }
        p {
            font-size: 22px;
            line-height: 32px;
        }
    }
    a.menu-trigger {
        display: none;
    }
    .product-list span {
        font-size: 14px;
    }
    .product-list-desc {
        top: 0;
        &.with-rating {
            top: 0;
        }
    }
    .arrows .with-ul {
        padding-right: 26px;
        &:after {
            right: 13px;
        }
    }
    .checkout-content {
        .half {
            width: 43.2%;
            &.first {
                padding: 0 30px 0 0;
            }
        }
        .fullwidth {
            width: 100%;
            select {
                width: 100%;
            }
        }
        select.fullwidth {
            width: 100%;
        }
    }
    .hide-on-mobile {
        display: none;
    }
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {

    .single {
        .post-img {
            margin: 23px 0;
        }
    }

    .container-xs{
        width:100%;
        .columns{
            width: 100% !important;
        }
    }
    .pagination-next-prev a.prev {
        margin: 0;
    }
    .slider-padding {
        padding-right: 0;
    }
    .og-details {
        display: inline-block;
        margin: 30px 0;
        padding: 0 40px;
    }
    .og-grid {
        margin-left: -8px;
    }
    .share-buttons {
        ul li:first-child a {
            display: none;
        }
        .social-p {
            display: block;
            font-weight: 700;
            margin: 20px 0;
        }
        a {
            margin-right: 8px;
        }
    }
    .extra-padding {
        padding: 0;
        &.left {
            padding: 0;
        }
    }
    .parallax-content h2 span {
        display: none;
    }
    .parallax-titlebar {
        #breadcrumbs ul {
            left: 55px;
            display: block;
            float: left;
            top: 60px;
        }
        h2 {
            transform: translateY(-100%);
        }
    }
    #not-found {
        margin: 15px 0 25px 0;
        h2 {
            font-size: 110px;
            line-height: 120px;
        }
        i {
            font-size: 105px;
            top: -2px;
        }
        p {
            font-size: 22px;
            line-height: 32px;
        }
    }
    .additional-menu {
        width:100%;
        float:none;
        margin-right: -10px;
        margin-left: -10px;
        margin-bottom: 0;
        ul{
            margin-top: -10px;
            display: flex;
            flex-wrap: wrap;
            margin-right: -20px;
            li{
                flex-grow: 1;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 10px;
                a{
                    display: block;
                    text-align: center;
                    padding: 10px;
                    background-color: $light-pink;
                    color: $dark-grey;
                    font-weight: bold;
                    &:hover{
                        color: $white;
                        background-color: $dark-grey;
                    }
                    text-transform: uppercase;
                    transition: all 0.5s;
                }
                &:last-child{
                    padding-right: 10px;
                }
            }
        }
    }
    .header-info-line{
        padding-left: 15px;
    }
    ul.top-bar-menu {
        padding-right: 0;
        li{
            &.auth-menu{
                font-weight: 700;
            }
        }
    }
    .header-cart{
        clear:right;
    }
    #cart {
        float: left;
    }

    .padding-xs {
        padding-left:10px;
        padding-right:10px;
    }
    #cart, .top-search {
        margin: 0;
        width:100%;
    }
    #top-bar .social-icons {
        display: none;
    }
    .parallax-title {
        font-size: 36px;
        span {
            padding: 10px 0 0 0;
            font-size: 24px;
        }
    }
    .the-team-content, .from-the-blog-content {
        //margin-bottom: 20px;
    }
    .shipping-icons,
    .payment-icons {
        float: left;
        margin: 15px 0 0 -5px;
    }
    .tp-banner-container, ul.product-list {
        margin-bottom: 35px;
    }
    a.img-caption {
        margin-bottom: 25px;
    }
    .caption {
        margin-top: -40px;
        &.dark h2, h2 {
            font-size: 38px;
            padding-bottom: 0;
        }
        &.dark h3, h3 {
            font-size: 24px;
            padding-bottom: 5px;
        }
        &.description {
            p {
                display: none;
            }
            width: auto;
        }
    }
    .orderby {
        margin: 40px 0 0 0;
    }
    .full-width .orderby, .products .pagination-next-prev a.prev {
        margin: 0;
    }
    .slider-padding {
        margin-bottom: 25px;
    }
    section.linking {
        border-bottom: 1px solid lighten($grey, 39%);
        padding-bottom: 25px;
    }
    .variables label {
        margin-top: 20px;
    }
    section.variables {
        margin-top: -20px;
    }
    .tabs-nav li {
        width: 100%;
    }
    #our-clients img {
        margin: 0 auto;
    }
    .counter-box, .icon-description, .related .portfolio-item {
        margin-bottom: 20px;
    }
    input.qty {
        padding: 8px 7px;
    }
    .cart-btns {
        margin-top: 10px;
    }
    .cart-btn{
        a.button{
            width: 100%;
            text-align: right;
        }
    }
    .stacktable {
        &.large-only {
            display: none;
        }
        &.small-only {
            display: table;
        }
    }
    table.cart-table.bottom {
        margin-top: 25px;
    }
    .container.cart {
        top: -25px;
    }
    .woo-search .search-form, .woo-search-main button {
        width: 100%;
    }
    .woo-search-elements {
        padding: 40px 30px 10px 10px;
        width: calc(100% - 100px);
        .variables {
            display: inline-block;
            width: 100%;
            .customSelect {
                margin-bottom: 8px;
            }
        }
    }
    .parallax-titlebar img {
        height: 100% !important;
        width: 100% !important;
        top: 0 !important;
        object-fit: cover !important;
    }
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .top-search input {
        width: 100%;
    }
    .apply-coupon input {
        width: 233px;
    }
    .checkout-content {
        .half {
            width: 44%;
            &.first {
                padding: 0 30px 0 0;
            }
        }
        .fullwidth {
            width: 100%;
            select {
                width: 100%;
            }
        }
        select.fullwidth {
            width: 100%;
        }
    }
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {
    .top-search input {
        width: 100%;
    }
    .apply-coupon input {
        width: 112px;
    }
    .top-search {
        float: none;
    }
    .checkout-content {
        .half {
            width: 95%;
            &.first {
                padding: 0 30px 0 0;
            }
        }
        .fullwidth {
            width: 100%;
            select {
                width: 100%;
            }
        }
        select.fullwidth {
            width: 100%;
        }
    }
}

/* ------------------------------------------------------------------- */
/* Styles Switcher
---------------------------------------------------------------------- */

#style-switcher {
    h3 {
        color: $white;
        font-size: 13px;
        margin: 5px 0 -5px 1px;
        font-weight: 600;
    }
    background: $dark-grey;
    width: 205px;
    position: fixed;
    top: 50px;
    z-index: 9999;
    left: -205px;
    div {
        padding: 2px 18px 10px 18px;
    }
    h2 {
        background-color: $black;
        color: $white;
        font-weight: bold;
        font-size: 14px;
        padding: 7px 0 6px 20px;
        a {
            background: url("../images/switcher.png") no-repeat 9px center transparent;
            background-color: $black;
            display: block;
            height: 43px;
            position: absolute;
            right: -43px;
            text-indent: -9999px;
            top: 0;
            width: 43px;
        }
    }
}

.colors {
    list-style: none;
    margin: 0 0 10px 0;
    overflow: hidden;
    li {
        float: left;
        margin: 4px 2px 0 2px;
        a {
            display: block;
            width: 17px;
            height: 17px;
            cursor: pointer;
        }
    }
}

div.layout-style {
    padding: 0 !important;
}

.layout-style select {
    width: 164px;
    padding: 5px;
    color: $grey;
    cursor: pointer;
    font-size: 13px;
    margin: 2px 0 10px 2px;
}

.green, .green-bg {
    background: $light-pink;
}

.blue, .blue-bg {
    background: $blue;
}

.orange, .orange-bg {
    background: $orange;
}

.navy, .navy-bg {
    background: $navy;
}

.yellow, .yellow-bg {
    background: $yellow;
}

.peach, .peach-bg {
    background: $peach;
}

.red, .red-bg {
    background: $red2;
}

.beige, .beige-bg {
    background: $beige;
}

.pink, .pink-bg {
    background: $pink;
}

.cyan, .cyan-bg {
    background: $cyan;
}

.celadon, .celadon-bg {
    background: $celadon;
}

.brown, .brown-bg {
    background: $brown;
}

.cherry, .cherry-bg {
    background: $cherry;
}

.gray, .gray-bg {
    background: darken($grey, 3%);
}

.purple, .purple-bg {
    background: $purple;
}

.darkcol, .dark-bg {
    background: $reddit;
}

.light {
    background: $light-grey;
}

.bg1 {
    background-image: url("../images/bg/squared_metal.png");
}

.bg2 {
    background-image: url("../images/bg/retina_wood.png");
}

.bg3 {
    background-image: url("../images/bg/brushed_alu.png");
}

.bg4 {
    background-image: url("../images/bg/crisp_paper_ruffles.png");
}

.bg5 {
    background-image: url("../images/bg/navy_blue.png");
}

.bg6 {
    background-image: url("../images/bg/bedge_grunge.png");
}

.bg7 {
    background-image: url("../images/bg/diamond_upholstery.png");
}

.bg8 {
    background-image: url("../images/bg/shattered.png");
}

.bg9 {
    background-image: url("../images/bg/light_toast.png");
}

.bg10 {
    background-image: url("../images/bg/noise_pattern_with_crosslines.png");
}

.bg11 {
    background-image: url("../images/bg/purty_wood.png");
}

.bg12 {
    background-image: url("../images/bg/subtle_stripes.png");
}

.bg13 {
    background-image: url("../images/bg/textured_stripes.png");
}

.bg14 {
    background-image: url("../images/bg/tileable_wood_texture.png");
}

.bg15 {
    background-image: url("../images/bg/stardust.png");
}

.bg16 {
    background-image: url("../images/bg/vichy.png");
}

.colors li a.active {
    box-shadow: 0 0 0 2px $white inset;
}

#reset {
    margin: 0 0 10px 2px;
    a.button {
        padding: 7px 14px;
        font-weight: 700;
        font-size: 13px;
    }
}

@media only screen and (max-width: 1024px) {
    #style-switcher {
        display: none;
    }
}
.home-slider-placeholder{
    height: 480px;
    margin: 0 0 50px 0;
}
.h1-title {
    font-weight: 500;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 36px;

    @include screen-sm-up {
        font-size: 32px;
        line-height: 50px;
    }
}
.h2-title {
    font-size: 18px;
    margin-bottom: 30px;
}

#chat-widget-container {
    z-index: 5001 !important;
}

.success {
    color: $success;
}
.danger {
    color: $error;
}

.warning {
    color: $warning;
}

.article-content ol{
    font-weight: bold;
    font-size: 18px;
    padding: 0;
    margin: 0 !important;
    margin-bottom: 25px !important;
    list-style-position: inside;
    li {
        margin-left: 0 !important;
    }
}

.color-light {
    color: #fff !important;
}

i.ianchor {
    color: $virusmask-main;
    &:hover{
        color: darken($virusmask-main, 10%);
    }
}

.mobile-menu-jpanel {
    position: relative;
    .new-label {
        position: absolute;
        top: -6px;
        right: -5px;
        padding: 3px 5px;
        color: #fff;
        background-color: #F12A23;
        text-transform: uppercase;
        font-size: 10px;
        z-index: 100;
    }

}

.i-c{
    display: inline-block;
    vertical-align: sub;
}

.prod-video-control {
    position: absolute;
    top: 0;
    left: 0;
    .icon-container {
        background-image: url("/images/video_thumbnail_play.svg");
        width: 60px;
        height: 60px;
        margin: 0 auto;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 18px;
        display: block;
    }
}

.no-product-found{
    margin-top: 30px;
    font-size: 20px;
}
.content-container{
    min-height: calc(100vh - 600);
}

.iti__country-list{
    z-index: 12 !important;
}
.owl-carousel .owl-nav .owl-prev.disabled,
.owl-carousel .owl-nav .owl-next.disabled{
    background-color: lighten($grey, 25%) !important;
    color: lighten($grey, 40%) !important;
    &.hover{
        background-color: lighten($grey, 25%) !important;
        color: lighten($grey, 40%) !important;
    }
}

#profileDd{
    ion-icon{
        font-size:26px;
    }
}
#emailAddressValidator {
    color: #664d03;
    background-color: #fff3cd;
    margin-top: 1rem !important;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

@include screen-sm-down{
    .select2-container.select2-container--bootstrap.select2-container--open{
        z-index: 3000 !important;
    }
}

.footer-links .highlighted-menu a {
    color: $red !important;
    font-weight: 700 !important;
    &:hover {
        color: $red !important;
        font-weight: 700 !important;
    }
}

.highlighted-text {
    ul {
        list-style: disc;
        padding-left: 40px;
    }
}