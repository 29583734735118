.health-fund-select-container{
    min-height: 30px;
    background: #4baf4f;
    text-align: center;
    color: #fff;
    font-size: 13px;
    position: relative;
    padding: 3px 30px 0 15px;

    i{
        font-weight: bold;
        cursor: pointer;
    }
    a {
        color:#fff;
        text-decoration: underline;
        white-space: nowrap;
        &:hover {
            text-decoration: none;
        }
    }

    span.close{
        position: absolute;
        right: 15px;
        font-size: 13px;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
.health-fund-select-popup-content {
    position: relative;
    background: #FFF;
    padding: 20px;
    width: auto;
    max-width: 800px;
    margin: 20px auto;
}
