.marketing-image {
  &-search {
    padding: 25px 15px;
    background: $white;
    &-helper {
      margin-left: 10px;
      margin-right: 10px;
    }
    &-form {
      margin-top: 25px;
      label {
        font-size: 16px;
        font-weight: 700;
        color: #333;
      }
      button {
        width: 10%;
        top: 24px;
        float: right;
        right: 10%;
        background-color: transparent;
        padding: 10px 12px;
        span {
          color: #333;
          font-size: 26px;
        }
      }
      input {
        display: inline-block;
        width: 90%;
        background-color: #f0f2f5;
        font-weight: 400;
        color: #333;
        border-left: 1px solid #cecece;
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        padding-right: 10%;
      }
    }
  }
  &-product {
    background: $white;
    h5 {
      min-height: 54px !important;
    }
    &-download {
      padding: 15px;
      background-color: $virusmask-main !important;
      color: $white;
      text-align: center;
      margin-top: 25px;
      &:hover, &:focus, &:active {
        color: $white;
      }
    }
  }
}
