h3 {
    &.package {
        margin-bottom: 15px;
    }
}

.package-row {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid lighten($grey, 39%);

    a {
        &.variant {
            padding: 5px;
            font-size: 12px;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.product {
    h6 {
        padding: 0 20px 10px 20px;
    }
}

.product-title {
    font-weight: 600;
    color: darken($grey, 13%);
}


.product-view {
    background-color: $white;

    table.basic-table {

        th {
            width: 30%;
        }
    }
    &.product-view-on-list{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-top:0px;
        .variant{
            display: flex;
            padding:18px 20px 0 20px;
            .variant-container {
                display: inline !important;
                .variant-name {
                    display: inline-block !important;

                }
            }
        }
    }
    .variant {
        display: table;

        .variant-container {
            display: table-row;

            .variant-name {
                display: table-cell;
                width: 120px;
                font-weight: 700;
                color: $dark-grey2;
                vertical-align: middle;
            }

            .variant-options {
                display: table-cell;

                a {
                    &.variant {
                        display: inline-block;
                        padding: 8px 15px 8px 12px;
                        font-size: 13px;
                        border: 1px solid #cecece;
                        margin-bottom: 5px;
                        -webkit-transition: background-color 0.2s ease-in-out;
                        -moz-transition: background-color 0.2s ease-in-out;
                        -o-transition: background-color 0.2s ease-in-out;
                        -ms-transition: background-color 0.2s ease-in-out;
                        transition: background-color 0.2s ease-in-out;
                        color: $dark-grey2;
                        position: relative;

                        &:hover, &:focus, &:active {
                            background-color: #e5f6f9;
                            border: 2px solid $light-blue;
                            padding: 7px 14px 7px 11px;
                            color: $light-blue;
                        }

                        &.selected-variant {
                            background-color: #e5f6f9;
                            border: 2px solid $light-blue;
                            padding: 7px 14px 7px 11px;
                            color: $light-blue;

                            &.inactive {
                                opacity: 0.8;
                                //background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M103 0 L-2 100' stroke='white' stroke-width='3'/></svg>");
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: 100% 100%, auto;
                                background-color: #333;
                                &:before {
                                    content: '';
                                    height: 1px;
                                    width: calc(100% - 25px);
                                    position: absolute;
                                    top: 47%;
                                    left: auto;
                                    background: #00a3c8;
                                }
                            }

                        }

                        &.inactive {
                            opacity: 0.5;
                            //background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M103 0 L-2 100' stroke='black' stroke-width='3'/></svg>");
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: 100% 100%, auto;
                            background-color: #f1f1f1;
                            &:before {
                                content: '';
                                height: 1px;
                                width: calc(100% - 25px);
                                position: absolute;
                                top: 47%;
                                left: auto;
                                background: #333;
                            }
                        }

                        &.virtual {
                            background-color: #92949c !important;
                            color: #f1f1f1 !important;
                            background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent));
                            background-image: repeating-linear-gradient(147deg, transparent 0%, transparent 48%, #000 50%, transparent 52%, transparent 100%);
                        }

                    }
                }
            }
        }
    }

    div.product-details {
        .tabs-nav {
            border-bottom: 1px solid #cccccc;

            a {
                font-size: 16px;
                padding: 10px 0;
                margin-right: 30px;
                font-weight: 400;
            }
        }
    }
}

.shipping-add {
    background-image: url("/images/hourglass.svg");
    background-repeat: no-repeat;
    padding-left: 35px;
    background-size: 30px;
    background-position: left center;
    color: $dark-grey2;
}

.order-history {
    .detail-view {
        td {
            padding: 5px;
        }

        th {
            padding: 5px;
        }
    }

    .purchase-label {
        font-weight: 700;
    }

    ul {
        li {
            margin-left: 10px;
            font-size: 12px;
        }
    }

    .st-new-item {
        @include screen-xs {
            display: none;
        }
    }
}

.variant {
    p {
        margin-bottom: 5px;
    }
}

@include screen-sm-down {
    .js-cart-add {
        width: 100%;
        margin: 15px 0 0 0 !important;
    }
    .chart-button {
        width: 100%;
        text-align: center !important;
    }

}

.product-price-discount {
    span.original-price {
        text-decoration: line-through;
        margin-right: 5px;
        color: #1A1A1A;
    }
}

.featured-cont {
    width: 800px;
    margin: 0 auto;
    @include screen-sm-down {
        width: inherit;
    }
}

/** no-more-tables BEGIN**/
@media only screen and (max-width: 992px) {

    .wb-xs {
        word-break: break-all;
    }
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
        display: block;
    }


    /* FLOATHEADER */

    .kv-thead-float {
        display: none;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    #no-more-tables tr {
        border: 1px solid #ccc;
    }

    #no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: left;
    }

    #no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }

    /*
    Label the data
    */
    #no-more-tables td:before {
        content: attr(data-title);
        line-height: 23px;
    }


    /* Force table to not be like tables anymore */
    .no-more-tables table,
    .no-more-tables thead,
    .no-more-tables tbody,
    .no-more-tables th,
    .no-more-tables td,
    .no-more-tables tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .no-more-tables tr {
        border: 1px solid #ccc;
    }

    .no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: left;
    }

    .no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }

    /*
    Label the data
    */
    .no-more-tables td:before {
        content: attr(data-title);
        line-height: 40px;
    }

    #no-more-tables td::after {
        content: "\00a0";
    }


}

h4 {
    &.purchase {
        margin: 15px 0;
    }
}

.product {
    section {
        h6 {
            color: #717171;
            font-size: 12px;

            p {
                line-height: 18px;
            }
        }

        h5 {
            &.extra {
                height: 60px !important;
                @media (max-width: 990px) and (min-width: 768px) {
                    height: 90px !important;
                }
                @media (max-width: 435px) {
                    height: 90px !important;
                }
            }
        }
    }

    &:hover .new-product-label {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    &:hover .new-size-product-label {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }

    &:hover .coupon-product-label {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }

    &:hover .black-friday-label {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }

}

.already-bought-container{
    border: 2px solid $virusmask-main;
    display: flex;
    margin-bottom: 30px;
    .already-bought-info{
        width: 56px;
        background-color: $virusmask-main;
        background-size: 35px 35px;
        text-align: center;
        font-size: 41px;
        padding-top: 8px;
        color:#fff;
    }
    .already-bought-text{
        padding:6px 15px;
        font-size: 16px;
        line-height: 21px;
        b {
            font-weight: 700 !important;
        }
        a{
            font-size: 14px;
        }
    }
}

.product-view {
    padding-top: 40px;
    @include screen-sm-down {
        padding-top: 10px;
    }

    p {
        margin: 0 0 5px 0;
    }

    .product-price-introductory {
        font-size: 16px;
        padding: 0 5px 10px;
        display:block;

        span {
            display: inline-block;
            background-color: #ddf9ff;
            font-size: 12px;
            text-transform: uppercase;
            color: $dark-grey2;
            margin-right: 10px;
            padding: 5px 10px;
            font-weight: 700;

            &.instock {
                background-color: #0ac781;
                color: $white;
            }
            &.out-of-stock{
                background-color: #fc461c;
                color: $white;
            }
        }
    }

    .related-product-card {
        font-size: 14px;
        padding: 0 0 0 0;
    }

    .coupon-discount-introductory {
        padding: 0 5px;
        font-size: 13px;
    }
}
.shipping-xl-text{
    font-size: 16px;
}

.product {
    .product-price-introductory {
        text-align: center;
        margin: 0 20px 20px 20px;
        border-top: 2px solid #e6e6e6;
        padding-top: 15px;
    }
}

.coupon-discount-introductory {
    font-style: italic;
    font-size: 14px;
    color: $virusmask-dark;
    background-color: lighten($red2, 35%);
}

.shipping-info {
    padding: 10px 0;
    margin-bottom: 0 !important;
}

.chart-button {
    font-size: 16px !important;
    padding: 7px 50px !important;

}
.shipping-add{
    font-size:24px !important;
}

.delivery-day {
    font-size: 18px;
    font-weight: 700;
}

.delivery-day-text {
    font-weight: 700;
}

.product-new {
    color: #ff0000;
    line-height: 28px;
    font-size: 16px;
    font-weight: 600;
}

.new-product-label {
    position: absolute;
    left: 0;
    top: 20px;
    z-index: 5;
    background-color: #cef5fe;
    padding: 6px 10px;
    transition: 0.5s;
    font-size: 13px;

    span {
        text-transform: uppercase;
        font-weight: 700;
        color: $dark-grey;
    }
}

.new-size-product-label {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 5;
    background-color: $red;
    padding: 6px 10px;
    transition: 0.5s;
    font-size: 13px;

    span {
        text-transform: uppercase;
        font-weight: 700;
        color: #f1f1f1;
    }

    @include screen-xs {
        padding: 5px 10px;
        font-size: 13px;
    }

    &.has-new-product-label {
        top: unset !important;
        bottom: 20px !important;
    }
}
.expiration-container{
    border:1px solid #FF4500;
    width: 100%;
    padding:10px;
    text-align: center;
    background-color:#FFECE8;
    margin-top:10px;
}
.expiration-text{
    font-size:14px;
    text-transform: uppercase;
    margin:0px !important;
    font-weight: 600;
}


.coupon-product-label {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 5;
    background-color: lighten($red, 20%);
    padding: 6px 10px;
    transition: 0.5s;
    font-size: 13px;

    span {
        text-transform: uppercase;
        font-weight: 700;
        color: #f1f1f1;
    }
    @include screen-xs {
        padding: 5px 10px;
        font-size: 13px;
    }
    &.has-new-product-label {
        top: unset !important;
        bottom: 20px !important;
    }
}

.extra-label {
    font-size: 14px !important;
    padding: 10px 15px !important;
}

.black-friday-label {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    background-color: #000;
    padding: 15px 20px;
    transition: 0.5s;
    font-size: 15px;

    span {
        text-transform: uppercase;
        font-weight: 700;
        color: #f1f1f1;
    }
}

.shopping-day-cont {
    display: none !important;

    .shopping-day {
        background-color: #e400a3;
        color: #fff;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        font-weight: 600;
    }

    @include screen-xs {
        display: block !important;
    }

}

.valentin-cont {
    display: none !important;

    .valentin {
        background-color: $light-pink;
        color: $dark-grey;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        font-weight: 600;
        border-right: 4px solid $light-pink;
        border-left: 4px solid $light-pink;

    }

    @include screen-xs {
        display: block !important;
    }
}


.black-friday-cont {
    display: none !important;

    .black-friday {
        background-color: #222222;
        color: #F12A23;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        font-weight: 600;
        border-right: 4px solid #f12023;
        border-left: 4px solid #f12023;

    }

    @include screen-xs {
        display: block !important;
    }
}


.black-friday-banner-desktop {
    display: block;
    @include screen-sm-down {
        display: none !important;
    }
}

.black-friday-banner-mobile {
    display: none;
    @include screen-sm-down {
        display: block !important;
    }
}


.in-stock-cont {
    display: none !important;

    .in-stock {
        background-color: #f6f6f6;
        color: #F12A23;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        font-weight: 600;
        border-right: 4px solid #f12023;
        border-left: 4px solid #f12023;

    }

    @include screen-xs {
        display: block !important;
    }
}

.shopping-day-countdown, .black-friday-countdown {
    display: none;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 46px;
    color: #1A1A1A;

    @include screen-xs {
        display: block !important;
    }
}

.shopping-day-img {
    display: none;
    @include screen-xs {
        display: block;
    }
}

.gift-wrapping {
    p {
        padding-top: 5px;
    }
}

#gallery {
    @include screen-xs {
        display: none !important;
    }

}

.js-gift-wrapping-minus, .js-gift-wrapping-plus {
    line-height: 26px;
}

.mobile-no {
    @include screen-xs {
        display: none !important;
    }
}

.mobile-only {
    @include screen-sm-up {
        display: none !important;
    }
}

.details-link {
    color: #fc6d8d;

    &:hover {
        color: #fc6d8d;
        text-decoration: underline;
    }
}

.product-description {
    font-size: 16px;

    ul {
        list-style: disc inside !important;
        margin: 15px !important;
    }
}

.product-page {
    ul {
        list-style: disc inside !important;
        margin: 15px !important;
    }
}

.line-thr {
    text-decoration: line-through;
    color: #acacac;
}

.video-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

h1 {
    &.item-list-manage {
        font-size: 20px !important;
        line-height: 34px !important;
    }
}

h2 {
    &.item-list-manage {
        font-size: 20px !important;
        line-height: 34px !important;
    }
}
.coupon-product-header-container {
    min-height: 30px;
    background: #F12A23;
    text-align: center;
    color: #fff;
    font-size: 13px;
    position: relative;
    padding: 3px 30px 0 15px;

    a {
        color: #fff;
        text-decoration: underline;
        white-space: nowrap;

        &:hover {
            text-decoration: none;
        }
    }

}

.items-wrap{
    display:flex;
    flex-wrap: wrap;
    .items{
        display: flex;
        .row-1{
            background-color: #f3f3f3;
        }
        .row-2{
            background-color: #00a3c8;
        }
        .row-3{
            background-color: #2a3964;
        }
    }
    .item-flex{
        display:flex;
    }
}


.item-list-image{
    .margin-bottom-30{
        margin-bottom:10px !important;
    }

}
.vip-box{
    border: 2px solid #cccccc;
    background-color: #ffffff;
    padding: 15px 30px 20px 15px;



}
.description-box-collapsible {
    @media (min-width: 991px) {
        display: none;
    }
    border: 2px solid #cccccc;
    background-color: #ffffff;
    padding: 15px 30px 20px 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    transition: 0.4s ease-in-out;
    p {
        line-height: 22px;
    }
    .short-text {
        p {
            margin-bottom: 14px;
        }
    }
    .description-box-btn {
        align-self: flex-end;
    }
}


.description-box-desktop {
    @media (max-width: 990px) {
        display: none !important;
    }
    .description-box-container {
        background-color: #fff;
        padding: 25px;
        height: 100%;
    }
}

.related-product-border {
    border: solid 2px $backgroundColor;
    margin-right: 4px !important;
}
.stacktable{
    .giftBoxSelect{
        max-width: 300px !important;
    }
}