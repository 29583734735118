.meta a {
    margin-right: 12px;
    display: inline-block;
}

.meta span {
    @include screen-sm-down {
        float: none;
    }
    @include screen-xs {
        display: block;
    }
}

.meta h2 {
    @include screen-lg-down {
        margin-bottom: 10px;
    }
}

.meta span a {
    margin-right: 0;
}

.from-the-blog-content {
    background-color: #fff;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: $light-grey;
    color: #3B3C41;
}


.blog-link {
    color: $virusmask-main !important;
    &:hover {
        color: darken($virusmask-main, 10%) !important;
    }
}
.post-list {
    background-color: transparent;

    .list-outer-container {
        background-color: transparent;
        .author-info {
            margin-top: 0;
        }
    }
}

.blogtag, .blogcategory {
    margin-right: 5px;
}

.tag-cloud {
    margin-top: 25px;
    h2 {
        margin-bottom: 10px;
    }
}

.also-like {
    margin: 25px 0;
}

.truncate-lead-item {
    color: $dark-grey2;
    margin-bottom: 15px;
    height: auto;
    a {
        color: $dark-grey2 !important;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        &:hover {
            color: lighten($dark-grey2, 15%) !important;
        }
    }
    @media (min-width: 991px) {
        height: 75px;
        overflow: hidden;
    }
    @media (max-width: 990px) and (min-width: 768px){
        height: 100px;
        overflow: hidden;
    }
}

.post-lead-date {
    font-size: 10px;
    color: $virusmask-blog-text;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 13px;
}

.date {
    @include screen-xs {
        display: none;
    }
}

.line-clamp-lead-item {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;

    @include screen-lg {
        min-height: 97px;
    }
    @include screen-md {
        min-height: 120px;
    }
    @include screen-sm {
        min-height: 192px;
    }

}

@media screen and (max-width: 1389px) and (min-width: 1200px) {
    .line-clamp-lead-item {
        min-height: 120px;
    }
}

.author-info {
    .picture {
        img{
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            margin-right: 10px;
        }
    }
    margin-bottom: 15px;
}

.next-prev-posts{

    border-bottom: 1px solid #E9EAEC;
    border-top: 1px solid #E9EAEC;
    padding: 40px 0px;
    margin-top: 40px;
}



.next-prev-posts .larrow,
.next-prev-posts .rarrow,
.next-prev-posts .picture,
.next-prev-posts .mini-lead{
    display: inline-block;
}
.next-post, .prev-post{
    width: 50%;
    display: table-cell;
}
.next-prev-posts .mini-lead{    /* <------------- */
    width: 74%;
    vertical-align: top;
}
@media screen and (max-width: 650px)
{
    .next-prev-posts .mini-lead{
        width: 50%;
    }
}
.next-post .mini-lead,
.next-post .rarrow,
.next-post .picture{
    float: right;
}
.next-post .mini-lead .label{

}
.mini-lead .link a{
    font-weight: bold;
}
.mini-lead .label{
    font-size: 14px;
    color: $dark-grey;
    font-weight: normal;
    padding: 0px;
}
.prev-post .mini-lead, .prev-post .mini-lead .label{
    text-align: left;
}
.next-post .mini-lead, .next-post .mini-lead .label{
    text-align: right;
}
.next-post .mini-lead {
    margin-right: 10px;
}
.prev-post .mini-lead {
    margin-left: 10px;
}
.next-prev-posts .picture{      /* <------------- */
    width: 17%;
    vertical-align: top;
}
.next-prev-posts .picture img{
    width: 60px;
    height: 60px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}
.next-prev-posts .larrow,
.next-prev-posts .rarrow{       /* <------------- */
    color: $virusmask-main;
    vertical-align: top;
    width: 3%;
    font-size: 24px;
    height: 60px; line-height: 55px;
}

.next-prev-posts .rarrow{
    margin-left: 5px;
}
.post-content .read-time{
    font-size: 14px;
}
.post {
    .post-content {
        p {
            color: $virusmask-blog-text;
            line-height: 30px;
            font-size: 16px;
            a {
                font-weight: 800 !important;
                &:hover, &:focus, &:active {
                    text-decoration: underline;
                    color: $virusmask-main !important;
                }
            }
            span {
                a {
                    font-weight: 800 !important;
                    &:hover, &:focus, &:active {
                        text-decoration: underline;
                        color: $virusmask-main !important;
                    }
                }
            }
            a {
                &:hover, &:focus, &:active {
                    color: $virusmask-main !important;
                }
                span {
                    font-weight: 800 !important;
                    &:hover, &:focus, &:active {
                        text-decoration: underline;
                        color: $virusmask-main !important;
                    }
                }
            }
        }
    }
    &.single {
        p {
            color: #555;
        }
    }
    ol,
    ul {
        display: block;
        list-style-type: disc;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;

        li {
            display: list-item;
            line-height: 20px;
            padding-bottom: 14px;
            font-size: 16px;
            a {
                span {
                    font-weight: 800 !important;
                    &:hover,&:focus,&:active {
                        color: $virusmask-main !important;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    ol {
        list-style-type: decimal;
    }
    .share-buttons {
        ul {
            list-style: none outside;
            margin: 0;
            padding-left: 0;
        }
    }
    @include screen-xs {
        .post-content {
            margin-left: 0 !important;
        }
        span {
            &.post-author {
                display: inline-block;
                float: left;
            }
        }
    }
    @include screen-phone {
        .post-content {
            .share-buttons {
                margin-top: 10px;
            }
        }
    }
}

.phone-date {
    display: none;
}

.product-dedicatory-widget {
    margin-top: 30px;
   .details-link {
        text-align: center;
        margin-top: 10px;
    }

    .product-name {
        font-size: 120%;
        margin-top: 10px;
    }

    .product-line {
        border-bottom: 1px solid #ebebeb;
        width: 100%;
        height: 1px;
        display: inline-block;
        margin: 15px 0;
    }

     figure {
        img {
            min-width: 100%;
        }
    }
    .preferred-products {
        a {
            margin-bottom: 12px;
            display: block;
            line-height: 1.5;
        }
    }
}

.index-blog-container {
    .post-list-item {
        .from-the-blog {
            border: 1px solid $white;
            .from-the-blog-content {
                border: none;
            }
        }
    }
}
.info-container{
    max-width: calc(100% - 100px);
}

.mb-50 {
    margin-bottom: 50px;
}