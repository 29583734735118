.address-box{
    &.checkout {
        cursor: pointer;
    }
    &.active{
        background-color: #f3f3f3;
        border: 1px solid $success;
        .address-control-box{
            border-top: 1px dashed #ccc;
        }
    }
    margin-bottom: 20px;
    border: 1px solid $light-grey;
    .address-details-box{
        .address-details-icon{
            font-size: 25px;
            margin-bottom: 20px;
        }
        padding: 20px;
        min-height: 250px;
        li{
            padding: 1px 0px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .address-control-box{
        padding: 10px 20px;
        border-top: 1px dashed $light-grey;
        line-height: 18px;
        a{
            display: inline-block;
        }
    }

    @include screen-xs{
        &.checkout{
            display:flex;
            flex-wrap: nowrap;
            margin-bottom: 15px;
            .address-details-box{
                flex-grow: 4;
            }
            .address-control-box{
                max-width: 80px;
                flex-grow: 1;
                border-top: 0;
                border-left: 1px dashed #ccc;
            }
        }
    }
}