.checkout-content {
    padding: 10px 20px;

    @include screen-xs{
        padding:0px;

        .ui-accordion .ui-accordion-content, .toggle-container{
            padding:0px;
        }
    }

    .headline-container {
        margin-bottom: 20px;
    }

    .checkout-tabs{
        .tabs-nav{
            display: flex;
            > li{
                flex-grow: 1;
                text-align: center;
            }
        }
    }
    .tabs-container{
        overflow: visible;
    }

    .stripe-button-el {
        overflow: hidden;
        display: inline-block;
        visibility: visible !important;
        -webkit-font-smoothing: antialiased;
        border: 0;
        padding: 1px;
        text-decoration: none;
        user-select: none;
        cursor: pointer;
        width:100%;
        text-transform: none;

        box-shadow: none !important;
        border-radius: 0 !important;
        height: 40px;
        line-height: 40px;
        background: #6772E5 url("/images/stripe-logo-2.png?231451231") no-repeat left 15px center !important;

        &:hover{
            background-color: lighten(#6772E5, 5%) !important;
        }
        &[disabled] {
            background-color: $grey !important;
        }
        .stripe-button-label{
            background-image: none !important;
            text-shadow: none !important;
            box-shadow: none !important;
            background-color: transparent !important;
            display: block;
            min-height: 30px;
            color:#fff;
        }
    }

    @include screen-sm-up{
        .input-placeholder{
            padding-top:47px;
        }
        .address-container{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.checkout-accordion-header {
    padding: 0 !important;

    .checkout-mode-radio {
        float: left;
        padding: 14px 15px 13px 15px;
        margin: 0 10px 0 0;
        background-color: darken($virusmask-main, 10%);
        &.disabled {
            background-color: #c0c0c0;
        }
    }

    .checkout-mode-image {
        float: left;
        margin-right: 15px;
        width: 60px;
        height: 53px;
        text-align: center;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        @include screen-xs {
            display:none;
        }
    }

    .checkout-mode-name {
        float: left;
        padding: 13px 0;
        @include screen-xs {
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 40px;
            white-space: nowrap;
        }
    }

    .checkout-mode-price {
        float: right;
        padding: 13px 20px;
    }
}

.checkout-accordion-content {

}

.checkout-address-select {
    display: flex;

    select {
        flex-grow: 8;
        width: 80%;
        display: inline-block;
    }

    button {
        flex-grow: 1;
        width: 10%;
        padding: 9px 14px;
        margin-right: 1px;
    }
}

/* Checkout
-------------------------------------- */

.checkout-section {
    background: $backgroundColor;
    color: $dark-grey;
    font-size: 16px;
    font-weight: 600;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin: 0 0 15px 0;

    &.active {
        background: $virusmask-main;
        color: $white;

        span {
            background: darken($virusmask-main, 10%);
            color: $white;
        }
    }

    &.pending {
        background: $backgroundColor;
        color: $dark-grey;

        span {
            background: darken($backgroundColor, 10%);
            color: $dark-grey;
        }
    }

    &.completed {
        background: $backgroundColor;
        color: $dark-grey;

        span {
            background: darken($backgroundColor, 10%);
            color: $dark-grey;
        }
    }
}

.checkout-section {
    a {
        color: $virusmask-dark;
    }
}

.checkout-section {
    p {
        position: relative;
        display: inline-block;
    }

    span {
        display: inline-block;
        padding: 14px 22px 12px 22px;
        font-weight: 700;
        font-size: 14px;
        margin: 0 10px 0 0;
        color: lighten($grey, 3%);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
}

.checkout-content, .checkout-delivery {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid lighten($grey, 40%);
}

.checkout-content {
    &.completed {
        margin-bottom: 20px;
        padding-bottom: 10px;
        //border-bottom: 0;
    }

    &.no-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

.checkout-summary {
    padding: 0 3px;
    margin-bottom: 0;
}

.checkout-delivery.active {
    border: 0;
    margin-bottom: 0;
}

.continue.button {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    margin: 0 0 30px 0;
}

/* Forms */

.checkout-content select, .checkout-summary select {
    padding: 10px 30px 10px 12px;
    cursor: pointer;
}

.checkout-content {
    .half {
        display: inline-block;
        width: 45.8%;

        &.first {
            padding: 0 30px 0 0;
        }
    }

    .fullwidth {
        display: inline-block;
        width: 100%;

        select {
            display: inline-block;
            width: 100%;
        }
    }

    select.fullwidth {
        display: inline-block;
        width: 100%;
    }

    input.fullwidth, .fullwidth input {
        display: inline-block;
        width: 95%;
    }

    .address-input-box{
        padding: 8px 10px;
        background-color: #fafafa;
        font-weight: 600;
        font-size: 13px;
        min-height: 50px;
        border: 1px solid #cecece;
    }
}

.checkout-section strong {
    float: right;
    font-size: 14px;
    margin: 13px 15px 0 0;
    color: darken($grey, 3%);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    i {
        margin: 0 3px 0 0;
    }
}

.otp-form{
    button{
        width:100%;
    }
}
.postapont_szuro {
    label{
        display: inline-block;
    }
}
@include screen-xs{
    .postapont_text{
        margin-bottom: 10px;
    }
    .postapont_details{
        margin-bottom: 10px;
    }
    .postapont_szuro{
        display: none;
    }

    #postaPontApi-content {
        max-width: 430px !important;
        margin:0px 0px !important;
    }
    #postaPontApi {
        max-width: none !important;
        width: 100% !important;
    }
    #postaPontApi-inner{
        padding:5px !important;
        max-width:430px !important;
    }
    #postaPontApi-inner form button{
        font-size:9px !important;
    }
    #postaPontApi-inner form button{
        width:100% !important;
        margin-bottom:10px;
    }
    #postaPontApi-inner form div#pp-select-container{
        width:100% !important;
        margin:15px 0px;
    }
}
@include screen-phone{
    #postaPontApi-content {
        max-width: 330px !important;
        margin:0px 0px !important;
    }
    #postaPontApi-inner{
        padding:5px !important;
        max-width:330px !important;
    }
}
.change-email-wrapper {
    margin: 20px 0;
    b {
        font-weight: bold;
    }
    .change-email-container{
        display: none;
        width: 40%;
        margin: 15px auto;
        .button-group{
            display: flex;
            input {
                flex-grow: 3;
            }
            button{
                flex-grow: 1;
            }
        }
        @include screen-sm-down {
            width: 100%;
        }
    }
}


#band-discount-continer {
    float: right;
    display: block;
    margin: 0 0 0 15px;
    position: relative;
    z-index: 100;

    width: 200px;
    @include screen-xs{
        width: 100%;
        padding-top: 20px;
    }

    .skill-bar{
        margin: 20px 0;
    }

    .skill-dd {
        position: absolute;
        right: 0;
        margin-top: 50px;
        background: lighten($grey, 43%);
        z-index: 9999;
        display: none;
        width: 260px;
        &.active {
            display: block;
            opacity: 1;
            visibility: visible;
        }
    }
}
.discount-row{
}
.original-price{
    text-decoration: line-through;
}
.discount-percent-container{
    text-transform: uppercase;
    font-weight: 700;
    color: #f1f1f1;
    background-color: #fc461c;
    padding: 3px 10px;
    transition: 0.5s;
    font-size: 12px;
    margin-top: -10px;
    vertical-align: bottom;
    @include screen-xs {
        position: static;
        font-size: 15px;
        padding: 3px 10px;
        display: inline-block;
    }
}


.select-city {
    .city-option {
        &:hover, &:active, &:focus {
            background: $virusmask-dark;
        }
    }
}

.package-receiving-select{
    height: 50px;
}