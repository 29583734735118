.package-receiving{
    margin-top: 32px;
    .place-name{
        font-weight:600;
        font-size:15px;
    }
    .opening-title{
        font-weight: 600;
    }
    .details{
        padding:15px 0px;
    }
    //.package-receiving-overlay {
    //    width: 95%;
    //    height: 100%;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    background: rgba(0,0,0,0.9);
    //    position: absolute;
    //    z-index: 1000;
    //    p {
    //        font-size: 24px;
    //        color: #fff;
    //    }
    //    &.city-set {
    //        display: none;
    //        z-index: 0;
    //    }
    //}
}
.pac-container{
    z-index:100000;
}

.p-relative {
    position: relative;
}