.manufacturer-title-container{
    .headline{
        display:block;
        margin: 0 auto;
        border: 0;
        text-align: center;
    }
    .line{
        border-bottom: 2px solid #00a3c8;
        display: block;
        margin: 0 auto;
        width: 50px;
        height: 2px;
    }
}
.manufacturer-logo-container{
    background-color: #f8f8f8;
    padding: 20px;
}
.manufacturer-page {
    ul {
        list-style: inherit;
        margin-left: 30px;
    }
    ul, ol {
        margin-bottom: 15px;
    }
}